import React from 'react';
import ConfettiIcon from '../../assets/confetti.png';
import classes from './Congrats.module.css';

function Congrats({ children }) {
  return (
    <div className={classes['congrats-container']}>
      <img
        src={ConfettiIcon}
        alt="Confetti"
        className={classes['confetti-icon']}
      />

      {children}
    </div>
  );
}

export default Congrats;
