import React from 'react';
import Button from '../../../../../../components/Button/Button';
import CheckIcon from '../../../../../../assets/check.png';
import classes from './OfferDescription.module.css';

function OfferDescription({ showOfferInfomationContentHandler }) {
  return (
    <div className={classes['your-offer-content']}>
      <h3 className={classes['key-points']}>Key Points</h3>

      <nav>
        <ul>
          <li>
            <img
              alt="Check"
              src={CheckIcon}
              className={classes['check-icon']}
            />
            <p>
              <span className={classes['bold']}>HiPo is not a debt</span> You
              pay HiPo from a % of your monthly income
            </p>
          </li>
          <li>
            <img
              alt="Check"
              src={CheckIcon}
              className={classes['check-icon']}
            />
            <p>
              <span className={classes['bold']}>
                {' '}
                The % you pay can decrease overtime
              </span>{' '}
              Each time you pay more than the base your % decreases, which
              lowers your base monthly payment
            </p>
          </li>
          <li>
            <img
              alt="Check"
              src={CheckIcon}
              className={classes['check-icon']}
            />
            <p>
              <span className={classes['bold']}>
                Pay $0 if income falls below $35k/yr{' '}
              </span>{' '}
              Unemployed, going back to school, or taking time off from work -
              you pay $0 if your income falls below 35k/year
            </p>
          </li>

          <li>
            <img
              alt="Check"
              src={CheckIcon}
              className={classes['check-icon']}
            />
            <p>
              <span className={classes['bold']}>Flexible timeframes</span> You
              can choose to pay the minimum or pay more to end your HiPo sooner
            </p>
          </li>

          <li>
            <img
              alt="Check"
              src={CheckIcon}
              className={classes['check-icon']}
            />
            <p>
              <span className={classes['bold']}>
                You can pay off your HiPo at anytime
              </span>{' '}
              There is no penalty for paying off early
            </p>
          </li>
        </ul>
      </nav>

      <div className={classes['actions-container']}>
        <Button onClick={showOfferInfomationContentHandler}>Next Steps</Button>
      </div>
    </div>
  );
}

export default OfferDescription;
