import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/estimated-lifetime-earnings-questionnaires`;

export const CREATE_ESTIMATED_LIFETIME_EARNINGS_QUESTIONNAIRE_ENDPOINT = BASE_ENDPOINT;
export const GET_ALL_ESTIMATED_LIFETIME_EARNINGS_ENDPOINT = BASE_ENDPOINT;

export const createEstimatedLifetimeEarningsQuestionnaireRequest = async function (
  requestBody
) {
  try {
    const { data } = await axios.post(
      CREATE_ESTIMATED_LIFETIME_EARNINGS_QUESTIONNAIRE_ENDPOINT,
      requestBody
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getAllEstimatedLifetimeEarningsRequest = async function () {
  try {
    const { data } = await axios.get(
      GET_ALL_ESTIMATED_LIFETIME_EARNINGS_ENDPOINT
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
