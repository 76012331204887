import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/canceled-user-offers`;

export const GET_MY_CANCELED_OFFERS_ENDPOINT = BASE_ENDPOINT;
export const GET_ALL_CANCELED_OFFERS_ENDPOINT = BASE_ENDPOINT + '/all';

export const getMyCanceledOffersRequest = async function () {
  try {
    const { data } = await axios.get(GET_MY_CANCELED_OFFERS_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getAllCanceledOffersRequest = async function (searchValue = '') {
  try {
    const { data } = await axios.get(
      `${GET_ALL_CANCELED_OFFERS_ENDPOINT}?searchValue=${searchValue}`
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
