import React, { useState } from 'react';
import classes from './YourOffer.module.css';
import OfferDescription from './components/OfferDescription/OfferDescription';
import OfferInformation from './components/OfferInformation/OfferInformation';
import { getFormattedNumber } from '../../../../utils/helpers';
import { useUserInformation } from '../../../../context/UserInformationContext';

function YourOffer({ setShowOffer }) {
  const [showDescritionContent, setShowDescriptionContent] = useState(true);
  const { myOfferInformation } = useUserInformation();

  const showOfferInfomationContentHandler = function () {
    setShowDescriptionContent(false);
  };

  if (!myOfferInformation) return null;

  return (
    <>
      <div className={classes['your-offer-container']}>
        <header className={classes['header']}>
          Your Offer Amount:{' '}
          {getFormattedNumber(myOfferInformation.offerAmount)} USD
        </header>
        {showDescritionContent ? (
          <OfferDescription
            showOfferInfomationContentHandler={
              showOfferInfomationContentHandler
            }
            setShowDescriptionContent={setShowDescriptionContent}
          />
        ) : (
          <OfferInformation setShowOffer={setShowOffer} />
        )}
      </div>
    </>
  );
}

export default YourOffer;
