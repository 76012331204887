import React, { useCallback, useMemo, useState } from 'react';
import classes from './Signup.module.css';
import Logo from '../../components/Logo/Logo';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Paragraph from '../../components/Paragraph/Paragraph';
import { Link, useNavigate } from 'react-router-dom';
import { isInputValid } from '../../utils/helpers';
import { useAuthentication } from '../../context/AuthenticationContext';
import { useNotifications } from '../../context/NotificationContext';

const INITIAL_SIGNUP_STATE = {
  firstName: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 1,
    label: 'First Name',
  },
  lastName: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 1,
    label: 'Last Name',
  },
  username: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 1,
    label: 'Username',
  },
  email: {
    value: '',
    touched: false,
    isValid: false,
    isEmail: true,
    label: 'Email',
  },
  password: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 6,
    label: 'Password',
  },
};

function Signup() {
  const navigate = useNavigate();
  const { signup } = useAuthentication();
  const { showNotification } = useNotifications();

  const [signupState, setSignupState] = useState(INITIAL_SIGNUP_STATE);
  const [signingUp, setSigningUp] = useState(false);

  const inputChangeHandler = useCallback(function (key, e) {
    const { value } = e.target;

    setSignupState((signupState) => {
      const updatedSignupState = { ...signupState };
      updatedSignupState[key].value = value;
      updatedSignupState[key].touched = true;
      updatedSignupState[key].isValid = isInputValid(
        value,
        updatedSignupState[key].minLength,
        updatedSignupState[key].isEmail
      );

      return updatedSignupState;
    });
  }, []);

  const renderInput = useCallback(
    function (key) {
      const { value, touched, isValid, label } = signupState[key];

      const inputStyle = {};
      if (touched && !isValid) {
        inputStyle['border'] = '1px solid red';
      }

      return (
        <Input
          onChange={(e) => inputChangeHandler(key, e)}
          label={label}
          placeholder="Enter here"
          key={key}
          value={value}
          style={inputStyle}
          type={key !== 'password' ? 'text' : 'password'}
        />
      );
    },
    [signupState, inputChangeHandler]
  );

  const isSignupButtonDisabled = useMemo(
    function () {
      let isFormValid = true;

      for (const key in signupState) {
        isFormValid = isFormValid && signupState[key].isValid;
      }

      return !isFormValid;
    },
    [signupState]
  );

  const signupHandler = async function () {
    setSigningUp(true);

    try {
      const requestBody = {
        firstName: signupState.firstName.value,
        lastName: signupState.lastName.value,
        username: signupState.username.value,
        email: signupState.email.value,
        password: signupState.password.value,
      };

      await signup(requestBody);

      const successMessage =
        'Registered successfully. You can login using your new email!';
      showNotification('success', successMessage, 2000);

      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      showNotification('error', error, 3000);
    }

    setSigningUp(false);
  };

  return (
    <>
      <div className={classes['signup-page']}>
        <div className={classes['signup-container']}>
          <div className={classes['logo-container']}>
            <Logo />
          </div>

          <div>
            <h2 className={classes['signup-text']}>Create your account</h2>
            <p className={classes['enter-details-text']}>
              Please enter your details to create your account
            </p>
          </div>

          <div className={classes['form-container']}>
            {Object.keys(signupState).map(renderInput)}
          </div>

          <div className={classes['signup-button-container']}>
            <Button
              onClick={signupHandler}
              disabled={isSignupButtonDisabled || signingUp}
              type="primary"
              style={{ marginBottom: '1rem' }}
            >
              Sign up for HiPo
            </Button>
            <Paragraph size="small" color="gray">
              Have an account?
              <Link to={'/login'}>
                <span className={classes['signup-link']}> Login </span>
              </Link>
            </Paragraph>
          </div>
        </div>
      </div>
    </>
  );
}

export default Signup;
