import React, { useState, useEffect } from 'react';
import classes from './AllUsers.module.css';
import { useInfiniteQuery } from 'react-query';
import { getAllUsersRequest } from '../../../../services/user';
import { useIntersectionObserver } from '../../../../hooks/useIntersectionObserver';
import { useNotifications } from '../../../../context/NotificationContext';
import { limitString } from '../../../../utils/helpers';
import Input from '../../../../components/Input/Input';
import LoadingSpinner from '../../../../components/LoadingSpinner/LoadingSpinner';

const RESULTS_PER_PAGE = 100;

function AllUsers() {
  const { showNotification } = useNotifications();
  const [searchUserInputValue, setSearchUserInputValue] = useState('');

  const {
    isLoading,
    isFetching,
    data,
    error,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery(
    ['all-users', searchUserInputValue],
    ({ pageParam = 1 }) =>
      getAllUsersRequest(pageParam, RESULTS_PER_PAGE, searchUserInputValue),
    {
      getNextPageParam: (lastPage, allPages) => {
        const numberOfPages = Math.ceil(lastPage.results / RESULTS_PER_PAGE);
        const nextPage = allPages.length + 1;

        return nextPage <= numberOfPages ? nextPage : undefined;
      },
      refetchOnWindowFocus: false,
    }
  );

  useIntersectionObserver({
    hasNextPage,
    fetchNextPage,
  });

  useEffect(
    function () {
      error && showNotification('error', error, 3000);
    },
    [error, showNotification]
  );

  return (
    <div className={classes['all-users-page-container']}>
      <div className={classes['all-users-search-container']}>
        <h2>All Users</h2>
        <Input
          style={{ width: 300 }}
          placeholder="Search here"
          value={searchUserInputValue}
          onChange={(e) => setSearchUserInputValue(e.target.value)}
        />
      </div>
      <div className={classes['all-users-list']}>
        <p>USER</p>
        <p>EMAIL</p>
        <p>ROLE</p>
        <p>EMAIL VERIFIED</p>

        <div className={classes['table-horizontal-line']}></div>

        {!isLoading && data && data.pages && data.pages[0].results === 0 && (
          <p style={{ opacity: '.4', gridColumn: '1/-1' }}>No Users Found!</p>
        )}

        {!isLoading &&
          data &&
          data.pages &&
          data.pages.map((page) => {
            return page.data.map((user) => {
              return (
                <div className={classes['user-container']} key={user._id}>
                  <div>
                    <img
                      width={44}
                      height={44}
                      className={classes['user-image']}
                      src={user.photo}
                      alt={user.fullName}
                    />
                    <p
                      title={user.fullName}
                      className={classes['user-fullName']}
                    >
                      {limitString(user.fullName, 20)}
                    </p>
                  </div>
                  <p>{user.email}</p>

                  <p>{user.role}</p>
                  <p>{user.emailVerified ? 'YES' : 'NO'}</p>

                  <div className={classes['table-horizontal-line']}></div>
                </div>
              );
            });
          })}

        {(isLoading || isFetching) && <LoadingSpinner />}
      </div>
    </div>
  );
}

export default AllUsers;
