import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/email-subscriptions`;

export const SUBSCRIBE_EMAIL_ENDPOINT = BASE_ENDPOINT;
export const GET_ALL_EMAIL_SUBSCRIPTIONS_ENDPOINT = BASE_ENDPOINT;

export const subscribeEmailRequest = async function (requestBody) {
  try {
    const { data } = await axios.post(SUBSCRIBE_EMAIL_ENDPOINT, requestBody);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getAllEmailSubscriptionsRequest = async function () {
  try {
    const { data } = await axios.get(GET_ALL_EMAIL_SUBSCRIPTIONS_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
