import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/user-onboarding-surveys/`;

export const ONBOARD_USER_SURVEY_ENDPOINT = BASE_ENDPOINT;
export const SAVE_ONBOARD_USER_SURVEY_ENDPOINT = BASE_ENDPOINT + 'save';
export const GET_ONBOARDING_SURVEY_DATA_ENDPOINT = BASE_ENDPOINT;
export const GET_EMPLOYMENT_HISTORY_DATA_ENDPOINT =
  BASE_ENDPOINT + 'employment-history';
export const GET_MY_ONBOARDING_SURVEY_DATA_ENDPOINT = BASE_ENDPOINT + 'mine';

export const onboardUserSurveyRequest = async function (requestBody) {
  try {
    const { data } = await axios.post(
      ONBOARD_USER_SURVEY_ENDPOINT,
      requestBody
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const saveOnboardUserSurveyRequest = async function (requestBody) {
  try {
    const { data } = await axios.post(
      SAVE_ONBOARD_USER_SURVEY_ENDPOINT,
      requestBody
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getOnboardingSurveyDataRequest = async function () {
  try {
    const { data } = await axios.get(GET_ONBOARDING_SURVEY_DATA_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getOnboardingSurveyEmploymentHistoryDataRequest = async function () {
  try {
    const { data } = await axios.get(GET_EMPLOYMENT_HISTORY_DATA_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getMyOnboardingSurveyDataRequest = async function () {
  try {
    const { data } = await axios.get(GET_MY_ONBOARDING_SURVEY_DATA_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
