import React, { useCallback, useEffect, useState } from 'react';
import classes from './UserOnboardingSurvey.module.css';
import GetStarted from '../GetStarted/GetStarted';
import Input from '../../../../components/Input/Input';
import { isInputValid } from '../../../../utils/helpers';
import Button from '../../../../components/Button/Button';
import { useNotifications } from '../../../../context/NotificationContext';
import {
  getMyOnboardingSurveyDataRequest,
  onboardUserSurveyRequest,
  saveOnboardUserSurveyRequest,
} from '../../../../services/userOnboardingSurvey';
import { useAuthentication } from '../../../../context/AuthenticationContext';
import { useQuery } from 'react-query';
import { useUserInformation } from '../../../../context/UserInformationContext';

const questions = [
  {
    id: 0,
    questionText: 'What is your full legal name?',
  },
  {
    id: 1,
    questionText: 'What is your birthdate?',
  },
  {
    id: 2,
    questionText: 'Please enter in your current full address',
  },
  {
    id: 3,
    questionText:
      'If you attended a University and have been awarded a Bachelors degree, please enter the name of your University. ',
  },
  {
    id: 4,
    questionText:
      'If you received a Bachelors degree, what was your degree awarded in (ie: Biology)',
  },
  {
    id: 5,
    questionText:
      'If you received a Bachelors degree, what was your GPA (ie. 3.0)',
  },
  {
    id: 6,
    questionText:
      'If you received a Bachelors degree, what year did you graduate?',
  },
  {
    id: 7,
    questionText: 'Please enter in your employment history',
  },
  {
    id: 8,
    questionText: 'What is your gross annual salary (before taxes)?',
  },
  {
    id: 9,
    questionText:
      'If you have credit card debt, what is your total credit card balance?',
  },
  {
    id: 10,
    questionText:
      'If you entered an amount in the previous question, what is your total monthly payment to all credit card accounts? ',
  },
  {
    id: 11,
    questionText:
      'If you have other debts besides for credit card debt (car loan, small business loan, personal loan, school loans etc.) please enter your total debt.',
  },
  {
    id: 12,
    questionText:
      'If you entered an amount in the previous question, what is your total monthly payment for all your combined debts (not including credit cards).',
  },
  {
    id: 13,
    questionText: 'Have you ever filed for bankruptcy before?',
  },
  {
    id: 14,
    questionText: 'How many credit card accounts do you have?',
  },
  {
    id: 15,
    questionText:
      'How many times have you been late on a credit card payment in the last 2 years (please estimate to the best of your ability).',
  },
  {
    id: 16,
    questionText: 'What is your credit score?',
  },
  {
    id: 17,
    questionText: 'Do you own your house?',
  },
  {
    id: 18,
    questionText: 'What is your current monthly rent or mortgage payment?',
  },
  {
    id: 19,
    questionText:
      'What is the total amount you are requesting from HiPo? For example, this amount may be the full amount of your credit card debt or only a partial amount?',
  },
];

const EMPLOYMENT_HISTORY_MODEL = {
  jobTitle: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 2,
    label: 'Job Title',
  },
  companyName: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 2,
    label: 'Company Name',
  },
  currentlyEmployed: false,
  startDate: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 5,
    label: 'Start Date',
  },
  endDate: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 5,
    label: 'End Date',
  },
};

function UserOnboardingSurvey() {
  const { refreshProfile } = useAuthentication();
  const { showNotification } = useNotifications();
  const { getMyInformationHandler } = useUserInformation();

  const { data, error } = useQuery({
    queryKey: ['my-onboarding-survey-data'],
    queryFn: getMyOnboardingSurveyDataRequest,
  });

  const [submittingSurvey, setSubmittingSurvey] = useState(false);
  const [savingSurvey, setSavingSurvey] = useState(false);
  const [showGetStartedPage, setShowGetStartedPage] = useState(true);

  const [answers, setAnswers] = useState({
    0: {
      firstName: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 1,
        label: 'First Name',
      },
      midName: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Mid Name',
      },
      lastName: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 1,
        label: 'Last Name',
      },
    },
    1: {
      birthDate: {
        value: '',
        touched: false,
        isValid: false,
      },
    },
    2: {
      street: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 2,
        label: 'Street Name',
      },
      city: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 2,
        label: 'City',
      },
      state: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 2,
        label: 'State',
      },
      'apt#': {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Apartment #',
      },
    },
    3: {
      universityName: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'University Name',
      },
    },
    4: {
      bachelorDegree: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Bachelor Degree',
      },
    },
    5: {
      bachelorDegreeGPA: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Bachelor Degree GPA',
        type: 'number',
        validationRequirements: {
          min: 0,
          max: 4,
        },
      },
    },
    6: {
      graduationYear: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Graduation Year',
        type: 'date',
      },
    },
    8: {
      grossAnnualSalary: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 1,
        label: 'Gross Annual Salary (USD)',
        type: 'number',
        validationRequirements: {
          min: 500,
        },
      },
    },
    9: {
      totalDebt: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Total Debt (USD)',
        type: 'number',
        validationRequirements: {
          min: 0,
        },
      },
    },
    10: {
      monthlyPayment: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Monthly Payment (USD)',
        type: 'number',
        validationRequirements: {
          min: 100,
        },
      },
    },
    11: {
      otherDebt: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Total Debt (USD)',
        type: 'number',
        validationRequirements: {
          min: 0,
        },
      },
    },
    12: {
      monthlyOtherDebtPayment: {
        value: '',
        touched: false,
        isValid: true,
        minLength: 0,
        label: 'Monthly Payment (USD)',
        type: 'number',
        validationRequirements: {
          min: 0,
        },
      },
    },
    14: {
      numberOfCreditCardAccounts: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 1,
        label: 'Number Of Credit Card Accounts',
        type: 'number',
        validationRequirements: {
          min: 0,
        },
      },
    },
    15: {
      numberOfTimesForDelayedCreditCardPayment: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 1,
        label: 'Number Of Times',
        type: 'number',
        validationRequirements: {
          min: 0,
        },
      },
    },
    16: {
      creditScore: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 1,
        label: 'Credit Score',
        type: 'number',
        validationRequirements: {
          min: 400,
        },
      },
    },
    18: {
      monthlyRentOrMortgagePayment: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 1,
        label: 'Monthly Rent Or Mortgage Payment (USD)',
        type: 'number',
        validationRequirements: {
          min: 0,
        },
      },
    },
    19: {
      requestedAmount: {
        value: '',
        touched: false,
        isValid: false,
        minLength: 1,
        label: 'Requested Amount (USD)',
        type: 'number',
        validationRequirements: {
          min: 0,
        },
      },
    },
  });

  const [employmentHistory, setEmploymentHistory] = useState([
    JSON.parse(JSON.stringify(EMPLOYMENT_HISTORY_MODEL)),
  ]);

  const [filedForBankruptcy, setFiledForBankruptcy] = useState(false);
  const [ownHouse, setOwnHouse] = useState(false);

  const SECTIONS_HEADERS = {
    1: `Basic Information`,
    2: `Education`,
    3: `Employment`,
    4: `Debts`,
    5: `Assets`,
    6: `HiPo Amount`,
  };

  const SECTIONS_QUESTIONS = {
    1: [0, 1, 2],
    2: [3, 4, 5, 6],
    3: [7, 8],
    4: [9, 10, 11, 12, 13, 14, 15, 16],
    5: [17, 18],
    6: [19],
  };

  const SECTIONS = {
    1: [
      () => renderAnswerInputs(0),
      () => renderDateInput(1),
      () => renderAnswerInputs(2),
    ],
    2: [
      () => renderAnswerInputs(3),
      () => renderAnswerInputs(4),
      () => renderAnswerInputs(5),
      () => renderDateInput(6),
    ],
    3: [() => renderEmploymentHistory(), () => renderAnswerInputs(8)],
    4: [
      () => renderAnswerInputs(9),
      () => renderAnswerInputs(10),
      () => renderAnswerInputs(11),
      () => renderAnswerInputs(12),
      () => renderFiledForBankruptcy(),
      () => renderAnswerInputs(14),
      () => renderAnswerInputs(15),
      () => renderAnswerInputs(16),
    ],
    5: [() => renderRentOrOwnHouse(), () => renderAnswerInputs(18)],
    6: [() => renderAnswerInputs(19)],
  };
  const [selectedSection, setSelectedSection] = useState(1);

  useEffect(
    function () {
      error && showNotification('error', error, 3000);
    },
    [error, showNotification]
  );

  useEffect(
    function () {
      if (!data?.data) return;

      const preFillAnswersBySavedSurveyHandler = function () {
        setAnswers((answers) => {
          const updatedAnswers = JSON.parse(JSON.stringify(answers));
          data.data.forEach(({ question, answer, employmentHistory }) => {
            const foundQuestion = questions.find(
              ({ questionText }) => questionText === question
            );
            if (foundQuestion) {
              const id = foundQuestion.id;

              if (updatedAnswers[id]) {
                const splittedAnswer = answer.split(' ');
                let answerCounter = 0;

                for (const key in answers[id]) {
                  updatedAnswers[id][key].value = splittedAnswer[answerCounter];
                  updatedAnswers[id][key].touched = !!splittedAnswer[
                    answerCounter
                  ];
                  updatedAnswers[id][key].isValid = isInputValid(
                    splittedAnswer[answerCounter],
                    updatedAnswers[id][key].minLength,
                    false,
                    updatedAnswers[id][key].validationRequirements || {}
                  );

                  answerCounter++;
                }
              }

              if (id === 13) {
                setFiledForBankruptcy(() => (answer === 'Yes' ? true : false));
              }

              if (id === 17) {
                setOwnHouse(() => (answer === 'Yes' ? true : false));
              }

              if (id === 7) {
                setEmploymentHistory(() => {
                  return employmentHistory.map(
                    ({
                      jobTitle,
                      companyName,
                      currentlyEmployed,
                      endDate,
                      startDate,
                    }) => {
                      const employmentHistoryModel = JSON.parse(
                        JSON.stringify(EMPLOYMENT_HISTORY_MODEL)
                      );
                      employmentHistoryModel.jobTitle.value = jobTitle;
                      employmentHistoryModel.jobTitle.touched = !!jobTitle;
                      employmentHistoryModel.jobTitle.isValid = isInputValid(
                        jobTitle,
                        employmentHistoryModel.jobTitle.minLength,
                        false
                      );

                      employmentHistoryModel.companyName.value = companyName;
                      employmentHistoryModel.companyName.touched = !!companyName;
                      employmentHistoryModel.companyName.isValid = isInputValid(
                        companyName,
                        employmentHistoryModel.companyName.minLength,
                        false
                      );

                      employmentHistoryModel.currentlyEmployed = currentlyEmployed;

                      if (!currentlyEmployed) {
                        employmentHistoryModel.startDate.value = startDate;
                        employmentHistoryModel.startDate.touched = !!startDate;
                        employmentHistoryModel.startDate.isValid = isInputValid(
                          startDate,
                          employmentHistoryModel.startDate.minLength,
                          false
                        );

                        employmentHistoryModel.endDate.value = endDate;
                        employmentHistoryModel.endDate.touched = !!endDate;
                        employmentHistoryModel.endDate.isValid = isInputValid(
                          endDate,
                          employmentHistoryModel.endDate.minLength,
                          false
                        );
                      }

                      return employmentHistoryModel;
                    }
                  );
                });
              }
            }
          });

          return updatedAnswers;
        });
      };

      preFillAnswersBySavedSurveyHandler();
    },
    [data]
  );

  const renderAnswerInputs = useCallback(
    function (index) {
      const inputChangeHandler = function (key, value) {
        const updatedAnswers = { ...answers };
        updatedAnswers[index][key].value = value;

        const multipleInputsKey = {
          firstName: true,
          midName: true,
          lastName: true,
          street: true,
          city: true,
          state: true,
          'apt#': true,
        };
        if (key in multipleInputsKey) {
          updatedAnswers[index][key].value = value.trim();
        }

        updatedAnswers[index][key].touched = true;
        updatedAnswers[index][key].isValid = isInputValid(
          value,
          updatedAnswers[index][key].minLength,
          false,
          updatedAnswers[index][key].validationRequirements || {}
        );

        setAnswers(updatedAnswers);
      };

      return (
        <div className={classes['row-inputs']}>
          {Object.keys(answers[index]).map((key) => {
            const style = {};
            if (answers[index][key].touched && !answers[index][key].isValid) {
              style['border'] = '1px solid red';
            }
            return (
              <Input
                key={key}
                style={style}
                placeholder={
                  answers[index][key].minLength === 0
                    ? 'Optional'
                    : 'Enter here'
                }
                label={answers[index][key].label}
                onChange={(e) => inputChangeHandler(key, e.target.value)}
                type={answers[index][key].type === 'number' ? 'number' : 'text'}
                value={answers[index][key].value}
              />
            );
          })}
        </div>
      );
    },
    [answers]
  );

  const renderDateInput = useCallback(
    function (index) {
      const dateChangeHandler = function (key, value) {
        const newDate = value;

        const isValidFormat = /^\d{4}-\d{2}-\d{2}$/.test(newDate);
        const isValidDate = new Date(newDate).toString() !== 'Invalid Date';
        const isInFuture = new Date(newDate) >= new Date();

        const updatedAnswers = { ...answers };
        updatedAnswers[index][key].touched = true;
        updatedAnswers[index][key].value = newDate;
        updatedAnswers[index][key].isValid =
          isValidFormat && isValidDate && !isInFuture;

        setAnswers(updatedAnswers);
      };

      return Object.keys(answers[index]).map((key) => {
        const style = {};
        if (answers[index][key].touched && !answers[index][key].isValid) {
          style['border'] = '1px  solid red';
        }

        return (
          <Input
            key={key}
            style={style}
            type="date"
            value={answers[index][key].value}
            onChange={(e) => dateChangeHandler(key, e.target.value)}
          />
        );
      });
    },
    [answers]
  );

  const employmentHistoryChangeHandler = function (key, index, value) {
    if (key === 'currentlyEmployed') {
      setEmploymentHistory((employmentHistory) => {
        const updatedEmploymentHistory = [...employmentHistory];
        updatedEmploymentHistory[index][key] = !updatedEmploymentHistory[index][
          key
        ];
        return updatedEmploymentHistory;
      });
    } else {
      setEmploymentHistory((employmentHistory) => {
        const updatedEmploymentHistory = [...employmentHistory];
        updatedEmploymentHistory[index][key].value = value;
        updatedEmploymentHistory[index][key].touched = true;
        updatedEmploymentHistory[index][key].isValid = isInputValid(
          value,
          updatedEmploymentHistory[index][key].minLength,
          false
        );

        return updatedEmploymentHistory;
      });
    }
  };

  const jobTitleChangeHandler = function (index, value) {
    employmentHistoryChangeHandler('jobTitle', index, value);
  };

  const companyNameChangeHandler = function (index, value) {
    employmentHistoryChangeHandler('companyName', index, value);
  };

  const currentlyEmployedChangeHandler = function (index) {
    employmentHistoryChangeHandler('currentlyEmployed', index);
  };

  const startDateChangeHandler = function (index, value) {
    employmentHistoryChangeHandler('startDate', index, value);
  };

  const endDateChangeHandler = function (index, value) {
    employmentHistoryChangeHandler('endDate', index, value);
  };

  const addAnotherEmployerHandler = function () {
    setEmploymentHistory((employmentHistory) => [
      ...employmentHistory,
      JSON.parse(JSON.stringify(EMPLOYMENT_HISTORY_MODEL)),
    ]);
  };

  const removeEmployerHandler = function (index) {
    setEmploymentHistory((employmentHistory) =>
      employmentHistory.filter((_, i) => i !== index)
    );
  };

  const renderEmploymentHistory = function () {
    return (
      <>
        <div className={classes['employment-history-container']}>
          {employmentHistory.map(
            (
              { jobTitle, companyName, currentlyEmployed, startDate, endDate },
              i
            ) => {
              const jobTitleStyle = {};
              if (jobTitle.touched && !jobTitle.isValid) {
                jobTitleStyle['border'] = '1px solid red';
              }
              const companyNameStyle = {};
              if (companyName.touched && !companyName.isValid) {
                companyNameStyle['border'] = '1px solid red';
              }

              return (
                <div key={i} className={classes['employment-history-inputs']}>
                  <Input
                    style={jobTitleStyle}
                    label="Job Title"
                    value={jobTitle.value}
                    onChange={(e) => jobTitleChangeHandler(i, e.target.value)}
                    placeholder="Enter here"
                  />
                  <Input
                    style={companyNameStyle}
                    label="Company Name"
                    placeholder="Enter here"
                    value={companyName.value}
                    onChange={(e) =>
                      companyNameChangeHandler(i, e.target.value)
                    }
                  />
                  <Input
                    label="Currently Employed"
                    type="checkbox"
                    checked={currentlyEmployed}
                    onChange={() => currentlyEmployedChangeHandler(i)}
                    placeholder="Enter here"
                  />
                  {!currentlyEmployed && (
                    <>
                      <Input
                        type="date"
                        value={startDate.value}
                        label="Start Date"
                        placeholder="Enter here"
                        onChange={(e) =>
                          startDateChangeHandler(i, e.target.value)
                        }
                      />
                      <Input
                        type="date"
                        value={endDate.value}
                        label="End Date"
                        placeholder="Enter here"
                        onChange={(e) =>
                          endDateChangeHandler(i, e.target.value)
                        }
                      />
                    </>
                  )}

                  <Button
                    onClick={() => removeEmployerHandler(i)}
                    type="danger"
                    style={{ marginLeft: 'auto' }}
                  >
                    Remove
                  </Button>
                </div>
              );
            }
          )}
        </div>

        <div className={classes['add-another-employer-button-container']}>
          <Button onClick={addAnotherEmployerHandler} type="primary">
            Add another employer
          </Button>
        </div>
      </>
    );
  };

  const renderFiledForBankruptcy = function () {
    return (
      <div className={classes['filed-for-bankruptcy-container']}>
        <div
          onClick={() => setFiledForBankruptcy(false)}
          className={`${classes['no-button-container']} ${
            !filedForBankruptcy && classes['no-selected-button']
          }`}
        >
          NO
        </div>

        <div
          onClick={() => setFiledForBankruptcy(true)}
          className={`${classes['yes-button-container']} ${
            filedForBankruptcy && classes['yes-selected-button']
          }`}
        >
          YES
        </div>
      </div>
    );
  };

  const renderRentOrOwnHouse = function () {
    return (
      <div className={classes['own-house-container']}>
        <div
          onClick={() => setOwnHouse(false)}
          className={`${classes['no-button-container']} ${
            !ownHouse && classes['no-selected-button']
          }`}
        >
          NO
        </div>

        <div
          onClick={() => setOwnHouse(true)}
          className={`${classes['yes-button-container']} ${
            ownHouse && classes['yes-selected-button']
          }`}
        >
          YES
        </div>
      </div>
    );
  };

  const prepareRequestBodyHandler = function () {
    const requestBody = [];

    questions.forEach(({ id, questionText }) => {
      if (id === 7) return;

      if (id === 13) {
        requestBody.push({
          question: questionText,
          answer: filedForBankruptcy ? 'Yes' : 'No',
        });
        return;
      }

      if (id === 17) {
        requestBody.push({
          question: questionText,
          answer: ownHouse ? 'Yes' : 'No',
        });
        return;
      }

      const answer = answers[id];

      let fullAnswer = '';
      let index = 0;

      for (const key in answer) {
        if (index === 0) {
          fullAnswer += `${answer[key].value}`;
        } else {
          fullAnswer += ` ${answer[key].value}`;
        }

        index++;
      }

      requestBody.push({
        question: questionText,
        answer: fullAnswer,
      });
    });

    requestBody.push({
      question: questions.find((el) => el.id === 7).questionText,
      answer: '',
      employmentHistory: employmentHistory.map(
        ({ companyName, jobTitle, currentlyEmployed, startDate, endDate }) => {
          return {
            jobTitle: jobTitle.value,
            companyName: companyName.value,
            currentlyEmployed,
            startDate: startDate.value,
            endDate: endDate.value,
          };
        }
      ),
    });

    return requestBody;
  };

  const submitOnboardingSurveyHandler = async function () {
    setSubmittingSurvey(true);

    try {
      const requestBody = prepareRequestBodyHandler();

      await onboardUserSurveyRequest(requestBody);

      showNotification('success', 'Survey completed successfully!', 3000);

      refreshProfile();
      getMyInformationHandler();
    } catch (err) {
      showNotification('error', err, 3000);
    }

    setSubmittingSurvey(false);
  };

  const saveOnboardingSurveyHandler = async function () {
    setSavingSurvey(true);

    try {
      const requestBody = prepareRequestBodyHandler();

      await saveOnboardUserSurveyRequest(requestBody);

      showNotification('success', 'Survey saved successfully!', 3000);

      await refreshProfile();
    } catch (err) {
      showNotification('error', err, 3000);
    }

    setSavingSurvey(false);
  };

  const goToNextSectionHandler = function () {
    setSelectedSection((selectedSection) => selectedSection + 1);
  };

  const goToPreviousSectionHandler = function () {
    setSelectedSection((selectedSection) => selectedSection - 1);
  };

  const renderProgress = function () {
    const progressInPercentage =
      selectedSection * (100 / Object.keys(SECTIONS).length);

    return (
      <div className={classes['progress-container']}>
        <div
          style={{ width: `${progressInPercentage}%` }}
          className={classes['progress-bar']}
        ></div>
      </div>
    );
  };

  // const answersJSX = [
  //   () => renderAnswerInputs(0),
  //   () => renderDateInput(1),
  //   () => renderAnswerInputs(2),
  //   () => renderAnswerInputs(3),
  //   () => renderAnswerInputs(4),
  //   () => renderAnswerInputs(5),
  //   () => renderDateInput(6),
  //   () => renderEmploymentHistory(),
  //   () => renderAnswerInputs(8),
  //   () => renderAnswerInputs(9),
  //   () => renderAnswerInputs(10),
  //   () => renderAnswerInputs(11),
  //   () => renderAnswerInputs(12),
  //   () => renderFiledForBankruptcy(),
  //   () => renderAnswerInputs(14),
  //   () => renderAnswerInputs(15),
  //   () => renderAnswerInputs(16),
  //   () => renderRentOrOwnHouse(),
  //   () => renderAnswerInputs(18),
  //   () => renderAnswerInputs(19),
  // ];

  const isSubmitButtonEnabled = function () {
    let isEnabled = true;

    for (const key in answers) {
      const answer = answers[key];
      for (const answerKey in answer) {
        isEnabled = isEnabled && answer[answerKey].isValid;
      }
    }

    employmentHistory.forEach(
      ({ jobTitle, companyName, currentlyEmployed, startDate, endDate }) => {
        isEnabled = isEnabled && jobTitle.isValid && companyName.isValid;

        if (!currentlyEmployed) {
          isEnabled = isEnabled && startDate.isValid && endDate.isValid;
        }
      }
    );

    return isEnabled;
  };

  if (showGetStartedPage)
    return <GetStarted setShowGetStartedPage={setShowGetStartedPage} />;

  return (
    <div className={classes['user-onboarding-container']}>
      <header className={classes['header']}>
        <h2 className={classes['header-text']}>
          {SECTIONS_HEADERS[selectedSection]}
        </h2>
        {renderProgress()}
      </header>

      <div className={classes['questions-answers-container']}>
        {SECTIONS[selectedSection].map((answerJSXFn, i) => (
          <div key={i} className={classes['question-answer-container']}>
            <p className={classes['question-text']}>
              {questions[SECTIONS_QUESTIONS[selectedSection][i]].questionText}
            </p>
            {answerJSXFn()}
          </div>
        ))}
      </div>

      <div className={classes['submit-button-container']}>
        <div className={classes['previous-next-container']}>
          <Button
            onClick={goToPreviousSectionHandler}
            disabled={selectedSection === 1}
          >
            Previous
          </Button>

          <Button
            onClick={goToNextSectionHandler}
            disabled={selectedSection === Object.keys(SECTIONS).length}
          >
            Next
          </Button>
        </div>

        <div className={classes['save-submit-container']}>
          <Button
            onClick={saveOnboardingSurveyHandler}
            disabled={savingSurvey}
            type="primary"
          >
            Save
          </Button>

          {selectedSection === Object.keys(SECTIONS).length && (
            <Button
              onClick={submitOnboardingSurveyHandler}
              disabled={!isSubmitButtonEnabled() || submittingSurvey}
              type="primary"
            >
              Submit
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

export default UserOnboardingSurvey;
