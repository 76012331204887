import React from 'react';
import classes from './AppLayout.module.css';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';

function AppLayout() {
  return (
    <main className={classes['app-layout-container']}>
      <div className={classes['sidebar-container']}>
        <Sidebar />
      </div>

      <Outlet />
    </main>
  );
}

export default AppLayout;
