import React, { useState } from 'react';
import classes from './UpdateRejectionReasonsModal.module.css';
import Modal from '../../../../../../components/Modal/Modal';
import Button from '../../../../../../components/Button/Button';
import { useNotifications } from '../../../../../../context/NotificationContext';
import { updateRejectionReasonsRequest } from '../../../../../../services/userOnboardingSurveyOfferStatus';

const REJECTION_REASONS = [
  'Income below threshold',
  'Not enough work history',
  'Credit score below threshold',
  'Too many missed payments',
  'Recent bankruptcy',
  'Amount requested is too high',
  'Suspected fraud',
];

function UpdateRejectionReasonsModal({
  updateRejectionReasonsModalState,
  closeModal,
  invalidateUsersBasedOnOnboardingSurveyOfferStatuses,
}) {
  const { _id, rejectionReasons, user } = updateRejectionReasonsModalState;

  const { showNotification } = useNotifications();

  const [selectedReasons, setSelectedReasons] = useState([...rejectionReasons]);
  const [updatingRejectionReasons, setUpdatingRejectionReasons] = useState(
    false
  );

  const selectReasonHandler = function (reason) {
    setSelectedReasons((reasons) => {
      if (reasons.includes(reason))
        return reasons.filter((el) => el !== reason);

      return [...reasons, reason];
    });
  };

  const updateRejectionReasonsHandler = async function () {
    setUpdatingRejectionReasons(true);
    try {
      const requestBody = {
        rejectionReasons: selectedReasons,
      };
      await updateRejectionReasonsRequest(_id, requestBody);

      invalidateUsersBasedOnOnboardingSurveyOfferStatuses();

      showNotification(
        'success',
        'Rejection reasons updated successfully!',
        1000
      );

      setTimeout(() => {
        setUpdatingRejectionReasons(false);
        closeModal();
      }, 1000);
    } catch (err) {
      setUpdatingRejectionReasons(false);

      showNotification('error', err, 3000);
    }
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={updatingRejectionReasons}
            type="danger"
            style={{ width: 200 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            disabled={updatingRejectionReasons || selectedReasons.length === 0}
            onClick={updateRejectionReasonsHandler}
          >
            Update Reasons
          </Button>
        </div>
      }
      closeModal={closeModal}
      title={`Update Rejection Reasons --- User: ${user.fullName}`}
    >
      <div>
        {REJECTION_REASONS.map((reason) => (
          <div key={reason} className={classes['reason-container']}>
            <label>
              <input
                type="checkbox"
                checked={selectedReasons.includes(reason)}
                onChange={() => selectReasonHandler(reason)}
              />
              {reason}
            </label>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default UpdateRejectionReasonsModal;
