import React from 'react';
import classes from './Logo.module.css';
import LightLogo from '../../assets/app-logo.png';

function Logo() {
  return (
    <div className={classes['logo-container']}>
      <img src={LightLogo} alt="HiPo" width={40} />
      <h2 className={classes['hipo-text']}>HiPo</h2>
    </div>
  );
}

export default Logo;
