import React from 'react';
import { useAuthentication } from './context/AuthenticationContext';
import { Navigate } from 'react-router-dom';

function AdminRoute({ redirectPath = '/', children }) {
  const { profile } = useAuthentication();

  if (profile.role !== 'admin') {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}

export default AdminRoute;
