import { createContext, useContext, useMemo, useState } from 'react';

const ADMIN_SIDEBAR_SECTIONS = {
  ALL_USERS: 'ALL_USERS',
  ONBOARDING_SURVEY: 'ONBOARDING_SURVEY',
  EMAIL_SUBSCRIPTIONS: 'EMAIL_SUBSCRIPTIONS',
  LIFETIME_EARNINGS: 'LIFETIME_EARNINGS',
  USER_OFFER: 'USER_OFFER',
  CANCELED_OFFERS: 'CANCELED_OFFERS',
  REJECTED_OFFERS: 'REJECTED_OFFERS',
};

const AdminPanelContext = createContext({
  ADMIN_SIDEBAR_SECTIONS,
  selectedSection: ADMIN_SIDEBAR_SECTIONS.ALL_USERS,
  setSelectedSection: function () {},
});

function AdminPanelProvider({ children }) {
  const [selectedSection, setSelectedSection] = useState(
    ADMIN_SIDEBAR_SECTIONS.ALL_USERS
  );

  const value = useMemo(() => {
    return {
      ADMIN_SIDEBAR_SECTIONS,
      selectedSection,
      setSelectedSection,
    };
  }, [selectedSection]);

  return (
    <AdminPanelContext.Provider value={value}>
      {children}
    </AdminPanelContext.Provider>
  );
}

const useAdminPanel = function () {
  const context = useContext(AdminPanelContext);
  if (context === undefined)
    throw new Error('AdminPanelContext was used outside of AdminPanelProvider');
  return context;
};

export { AdminPanelProvider, useAdminPanel };
