import React, { useCallback, useMemo, useState } from 'react';
import classes from './Login.module.css';
import Logo from '../../components/Logo/Logo';
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';
import Paragraph from '../../components/Paragraph/Paragraph';
import { Link } from 'react-router-dom';
import { isInputValid } from '../../utils/helpers';
import { useAuthentication } from '../../context/AuthenticationContext';
import { useNotifications } from '../../context/NotificationContext';

const INITIAL_LOGIN_STATE = {
  email: {
    value: '',
    touched: false,
    isValid: false,
    isEmail: true,
    label: 'Email',
  },
  password: {
    value: '',
    touched: false,
    isValid: false,
    minLength: 6,
    label: 'Password',
  },
};

function Login() {
  const { login } = useAuthentication();
  const { showNotification } = useNotifications();

  const [loginState, setLoginState] = useState(
    JSON.parse(JSON.stringify(INITIAL_LOGIN_STATE))
  );

  const [logingIn, setLogingIn] = useState(false);

  const inputChangeHandler = useCallback(function (key, e) {
    const { value } = e.target;

    setLoginState((loginState) => {
      const updatedLoginState = { ...loginState };
      updatedLoginState[key].value = value;
      updatedLoginState[key].touched = true;
      updatedLoginState[key].isValid = isInputValid(
        value,
        updatedLoginState[key].minLength,
        updatedLoginState[key].isEmail
      );

      return updatedLoginState;
    });
  }, []);

  const renderInput = useCallback(
    function (key) {
      const { value, touched, isValid, label } = loginState[key];

      const inputStyle = {};
      if (touched && !isValid) {
        inputStyle['border'] = '1px solid red';
      }

      return (
        <Input
          onChange={(e) => inputChangeHandler(key, e)}
          label={label}
          placeholder="Enter here"
          key={key}
          value={value}
          style={inputStyle}
          type={key !== 'password' ? 'text' : 'password'}
        />
      );
    },
    [loginState, inputChangeHandler]
  );

  const isLoginButtonDisabled = useMemo(
    function () {
      let isFormValid = true;

      for (const key in loginState) {
        isFormValid = isFormValid && loginState[key].isValid;
      }

      return !isFormValid;
    },
    [loginState]
  );

  const loginHandler = async function () {
    setLogingIn(true);

    try {
      const requestBody = {
        email: loginState.email.value,
        password: loginState.password.value,
      };
      await login(requestBody);
    } catch (err) {
      showNotification('error', err, 3000);
    }

    setLogingIn(false);
  };

  return (
    <>
      <div className={classes['login-page']}>
        <div className={classes['login-container']}>
          <div className={classes['logo-container']}>
            <Logo />
          </div>

          <div>
            <h2 className={classes['login-text']}>Log in to your account</h2>
            <p className={classes['enter-details-text']}>
              Please enter your details to access your account
            </p>
          </div>

          <div className={classes['form-container']}>
            {Object.keys(loginState).map(renderInput)}
          </div>

          <div className={classes['login-button-container']}>
            <Button
              onClick={loginHandler}
              disabled={isLoginButtonDisabled || logingIn}
              type="primary"
              style={{ marginBottom: '1rem' }}
            >
              Login
            </Button>

            <Paragraph size="small" color="gray">
              Don’t have an account?
              <Link to={'/signup'}>
                <span className={classes['signup-link']}> Sign Up </span>
              </Link>
            </Paragraph>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
