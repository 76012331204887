import React from 'react';
import { useNotifications } from '../../../../context/NotificationContext';
import Button from '../../../../components/Button/Button';
import {
  getOnboardingSurveyDataRequest,
  getOnboardingSurveyEmploymentHistoryDataRequest,
} from '../../../../services/userOnboardingSurvey';
import { generateCSVLink } from '../../../../utils/helpers';

const questions = [
  'What is your full legal name?',
  'What is your birthdate?',
  'Please enter in your current full address',
  'If you attended a University and have been awarded a Bachelors degree, please enter the name of your University. ',
  'If you received a Bachelors degree, what was your degree awarded in (ie: Biology)',
  'If you received a Bachelors degree, what was your GPA (ie. 3.0)',
  'If you received a Bachelors degree, what year did you graduate?',
  // 'Please enter in your employment history',
  'What is your gross annual salary (before taxes)?',
  'If you have credit card debt, what is your total credit card balance?',
  'If you entered an amount in the previous question, what is your total monthly payment to all credit card accounts? ',
  'If you have other debts besides for credit card debt (car loan, small business loan, personal loan, school loans etc.) please enter your total debt.',
  'If you entered an amount in the previous question, what is your total monthly payment for all your combined debts (not including credit cards).',
  'Have you ever filed for bankruptcy before?',
  'How many credit card accounts do you have?',
  'How many times have you been late on a credit card payment in the last 2 years (please estimate to the best of your ability).',
  'What is your credit score?',
  'Do you own your house?',
  'What is your current monthly rent or mortgage payment?',
  'What is the total amount you are requesting from HiPo? For example, this amount may be the full amount of your credit card debt or only a partial amount?',
];

const shortQuestions = [
  'Full_name?',
  'Birthdate',
  'Address',
  'University ',
  'Degree',
  'GPA',
  'Graduation_year',
  // 'Employment',
  'Income',
  'Total_credit_card_debt',
  'Monthly_credit_card_payment',
  'Other_debt',
  'Monthly_other_debt_payment',
  'Bankruptcy',
  'Number_credit_cards',
  'Number_missed_payments',
  'Credit_score',
  'Own_house',
  'Monthly_house_payment',
  'HiPo_amount',
];

function OnboardingSurveyData() {
  const { showNotification } = useNotifications();

  const exportOnboardingSurveyDataAsCSVHandler = async function () {
    try {
      const { data } = await getOnboardingSurveyDataRequest();

      const pivotData = data.filter(
        ({ user }) => user.onboardingSurveyCompleted
      );

      const csvContent = generateCSVContent(pivotData);

      generateCSVLink(csvContent, 'survey_data.csv');
    } catch (err) {
      showNotification('error', err, 3000);
    }
  };

  const exportEmploymentHistoryAsCSVHandler = async function () {
    try {
      const { data } = await getOnboardingSurveyEmploymentHistoryDataRequest();

      const employmentHistoryData = data.filter(
        ({ user }) => user.onboardingSurveyCompleted
      );

      const employmentHistoryCSV = generateEmploymentHistoryCSV(
        employmentHistoryData
      );

      generateCSVLink(employmentHistoryCSV, 'employment_history.csv');
    } catch (err) {
      showNotification('error', err, 3000);
    }
  };

  function generateCSVContent(pivotData) {
    let csvContent = 'User ID,User Full Name,';
    const uniqueQuestions = new Set();

    pivotData.forEach((group) => {
      group.data.forEach((entry) => {
        uniqueQuestions.add(entry.question);
      });
    });

    const sortedQuestions = [...questions];

    csvContent +=
      sortedQuestions
        .map((_question, i) => `"${shortQuestions[i]}"`)
        .join(',') + '\n';

    pivotData.forEach((group) => {
      const rowData = {
        'User ID': group.user._id,
        'User Full Name': group.user.fullName,
      };

      sortedQuestions.forEach((question) => {
        const entry = group.data.find((entry) => entry.question === question);
        rowData[question] = entry ? entry.answer : '';
      });

      csvContent += Object.values(rowData).join(',') + '\n';
    });

    return csvContent;
  }

  function generateEmploymentHistoryCSV(employmentHistoryData) {
    const csvHeader =
      'User ID,User Full Name,Job Title,Company Name,Currently Employed,Start Date,End Date\n';

    const csvRows = employmentHistoryData
      .map((entry) => {
        const userId = entry.user._id;
        const userName = entry.user.fullName;

        let sortedEmployment;

        if (entry.employmentHistory.length === 1) {
          sortedEmployment = entry.employmentHistory;
        } else {
          const index = entry.employmentHistory.findIndex(
            (el) => el.currentlyEmployed
          );
          if (index !== -1) {
            sortedEmployment = [entry.employmentHistory[index]];
          } else {
            entry.employmentHistory.sort(
              (a, b) => new Date(b.endDate) - new Date(a.endDate)
            );
            sortedEmployment = entry.employmentHistory;
          }
        }

        if (!sortedEmployment.length) {
          return `"${userId}","${userName}"`;
        }

        const {
          jobTitle,
          companyName,
          currentlyEmployed,
          startDate,
          endDate,
        } = sortedEmployment[0];

        return `"${userId}","${userName}","${jobTitle}","${companyName}","${
          currentlyEmployed ? 'Yes' : 'No'
        }","${startDate}","${endDate}"`;
      })
      .join('\n');

    return csvHeader + csvRows;
  }

  return (
    <div>
      <Button type="primary" onClick={exportOnboardingSurveyDataAsCSVHandler}>
        EXPORT CSV
      </Button>

      <Button type="primary" onClick={exportEmploymentHistoryAsCSVHandler}>
        EXPORT EMPLOYMENT HISTORY
      </Button>
    </div>
  );
}

export default OnboardingSurveyData;
