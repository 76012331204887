import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  getMyProfileRequest,
  loginRequest,
  signupRequest,
} from '../services/authentication';
import axios from 'axios';
import { useNotifications } from './NotificationContext';

const AuthenticationContext = createContext();

const setAuthorizationHeadersOnAxios = function (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const removeTokenFromAxiosHeaders = function () {
  delete axios.defaults.headers.common['Authorization'];
};

const saveTokenOnLocalStorage = function (token) {
  localStorage.setItem('jwt', token);
};

const removeTokenFromLocalStorage = function () {
  localStorage.removeItem('jwt');
};

const AuthenticationProvider = function ({ children }) {
  const { showNotification } = useNotifications();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [profile, setProfile] = useState(null);
  const [checkingAutoLogin, setCheckingAutoLogin] = useState(true);

  const setProfileHandler = useCallback(async function (token) {
    setAuthorizationHeadersOnAxios(token);
    saveTokenOnLocalStorage(token);

    try {
      const { data } = await getMyProfileRequest();

      setIsLoggedIn(true);
      setProfile(data);
    } catch (err) {
      throw err;
    }
  }, []);

  useEffect(
    function () {
      const autoLogin = async function () {
        const token = localStorage.getItem('jwt');

        if (token) {
          try {
            await setProfileHandler(token);
          } catch (err) {
            showNotification('error', err, 3000);
          }
        }

        setCheckingAutoLogin(false);
      };

      autoLogin();
    },
    [setProfileHandler, showNotification]
  );

  const refreshProfile = useCallback(async function () {
    try {
      const { data } = await getMyProfileRequest();

      setProfile(data);
    } catch (err) {
      throw err;
    }
  }, []);

  const signup = useCallback(async function (requestBody) {
    try {
      await signupRequest(requestBody);
    } catch (err) {
      throw err;
    }
  }, []);

  const login = useCallback(
    async function (requestBody) {
      try {
        const { data: token } = await loginRequest(requestBody);

        await setProfileHandler(token);
      } catch (err) {
        throw err;
      }
    },
    [setProfileHandler]
  );

  const logout = useCallback(function () {
    removeTokenFromAxiosHeaders();
    removeTokenFromLocalStorage();

    setProfile(null);
    setIsLoggedIn(false);
  }, []);

  const value = useMemo(
    function () {
      return {
        isLoggedIn,
        profile,
        signup,
        login,
        checkingAutoLogin,
        logout,
        refreshProfile,
      };
    },
    [
      isLoggedIn,
      profile,
      signup,
      login,
      checkingAutoLogin,
      logout,
      refreshProfile,
    ]
  );

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthentication = function () {
  const context = useContext(AuthenticationContext);
  if (context === undefined)
    throw new Error(
      'AuthenticationContext was used outside of AuthenticationProvider'
    );
  return context;
};

export { AuthenticationProvider, useAuthentication };
