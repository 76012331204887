import React from 'react';
import classes from './ProfileContainer.module.css';
import { useAuthentication } from '../../../../context/AuthenticationContext';

function ProfileContainer() {
  const { profile } = useAuthentication();

  return (
    <div className={classes['profile-container']}>
      <img
        alt={profile.fullName}
        src={profile.photo}
        className={classes['profile-photo']}
      />

      <div>
        <p className={classes['fullName']}>{profile.fullName}</p>
      </div>
    </div>
  );
}

export default ProfileContainer;
