export const handleError = (error) => {
  if (error.response?.status === 401) {
    setTimeout(() => {
      window.location.href = '/login';
    }, 2000);
    return 'You are not logged in. Please log in to perform this action!';
  }

  return error.response.data.message;
};
