import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/user-onboarding-survey-offer-statuses`;

export const GET_ALL_USERS_BASED_ON_ONBOARDING_SURVEY_OFFER_STATUS_ENDPOINT = BASE_ENDPOINT;
export const REJECT_USER_ONBOARDING_SURVEY_OFFER_STATUS_ENDPOINT = BASE_ENDPOINT;
export const GET_MY_ONBOARDING_SURVEY_OFFER_STATUS_ENDPOINT =
  BASE_ENDPOINT + '/mine';
export const UPDATE_USER_ONBOARDING_SURVEY_OFFER_STATUS_TO_PENDING_ENDPOINT = BASE_ENDPOINT;
export const UPDATE_REJECTION_REASONS_ENDPOINT = BASE_ENDPOINT;
export const GET_ONBOARDING_SURVEY_OFFER_INFORMATION_FOR_ALL_USERS_ENDPOINT =
  BASE_ENDPOINT + '/all/info';

export const getAllUsersBasedOnOnboardingSurveyOfferStatusRequest = async function (
  status,
  searchValue
) {
  try {
    let endpoint = `${GET_ALL_USERS_BASED_ON_ONBOARDING_SURVEY_OFFER_STATUS_ENDPOINT}?searchValue=${searchValue}`;
    if (status) {
      endpoint = `${GET_ALL_USERS_BASED_ON_ONBOARDING_SURVEY_OFFER_STATUS_ENDPOINT}?status=${status}&searchValue=${searchValue}`;
    }

    const { data } = await axios.get(endpoint);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const rejectUserOnboardingSurveyOfferStatusRequest = async function (
  userId,
  requestBody
) {
  try {
    const { data } = await axios.patch(
      `${REJECT_USER_ONBOARDING_SURVEY_OFFER_STATUS_ENDPOINT}/${userId}/reject`,
      requestBody
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateOnboardingSurveyOfferStatusToPendingRequest = async function (
  userId
) {
  try {
    const { data } = await axios.patch(
      `${UPDATE_USER_ONBOARDING_SURVEY_OFFER_STATUS_TO_PENDING_ENDPOINT}/${userId}/pending`
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getMyOnboardingSurveyOfferStatusRequest = async function () {
  try {
    const { data } = await axios.get(
      GET_MY_ONBOARDING_SURVEY_OFFER_STATUS_ENDPOINT
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const updateRejectionReasonsRequest = async function (
  userOnboardingSurveyOfferStatusId,
  requestBody
) {
  try {
    const { data } = await axios.patch(
      `${UPDATE_REJECTION_REASONS_ENDPOINT}/${userOnboardingSurveyOfferStatusId}/rejection-reasons`,
      requestBody
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getOnboardingSurveyOfferInformationForAllUsersRequest = async function () {
  try {
    const { data } = await axios.get(
      GET_ONBOARDING_SURVEY_OFFER_INFORMATION_FOR_ALL_USERS_ENDPOINT
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
