import React from 'react';
import classes from './Paragraph.module.css';

function Paragraph(props) {
  const { color, size = 'medium' } = props;

  return (
    <p
      className={`${classes['paragraph']} ${color && classes[color]} ${
        classes[size]
      }`}
      {...props}
    >
      {props.children}
    </p>
  );
}

export default Paragraph;
