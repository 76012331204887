import React from 'react';
import classes from './SeeDetailsModal.module.css';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';
import { useUserInformation } from '../../../context/UserInformationContext';
import moment from 'moment';

function SeeDetailsModal({ setShowSeeDetailsModal }) {
  const {
    myOfferInformation,
    myOnboardingSurveyOfferStatusInformation,
  } = useUserInformation();

  const closeModalHandler = function () {
    setShowSeeDetailsModal(false);
  };

  const renderDetailsHandler = function () {
    if (myOnboardingSurveyOfferStatusInformation.status === 'Accepted') {
      return (
        <>
          <div className={classes['offer-information-container']}>
            <div className={classes['hipo-text-container']}>
              <h3 className={classes['hipo-text']}>HiPo</h3>
            </div>

            <div className={classes['info-container']}>
              <div className={classes['info-section-container']}>
                <p>Offer Amount</p>
                <p>{myOfferInformation.offerAmount}</p>
              </div>

              <div className={classes['info-section-container']}>
                <p>Estimated Monthly Payment</p>
                <p>{myOfferInformation.estimatedMonthlyPayment}</p>
              </div>

              <div className={classes['info-section-container']}>
                <p>Percent of Monthly Income</p>
                <p>{myOfferInformation.monthlyPercentIncome}%</p>
              </div>

              <div className={classes['info-section-container']}>
                <p>Pay Off In</p>
                <p>
                  {moment(myOfferInformation.payOffIn).format('MMMM Do YYYY')}
                </p>
              </div>
            </div>
          </div>
        </>
      );
    } else if (myOnboardingSurveyOfferStatusInformation.status === 'Rejected') {
      return (
        <div className={classes['rejection-reasons-container']}>
          <h3 className={classes['rejection-reasons-text']}>
            Rejection Reasons
          </h3>
          {myOnboardingSurveyOfferStatusInformation.rejectionReasons.map(
            (reason, i) => {
              return (
                <p className={classes['rejection-reason']}>
                  {i + 1}. {reason}
                </p>
              );
            }
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <Modal
      footer={
        <div>
          <Button
            type="danger"
            style={{ width: 200 }}
            onClick={closeModalHandler}
          >
            Close
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title="See Details"
    >
      <div>{renderDetailsHandler()}</div>
    </Modal>
  );
}

export default SeeDetailsModal;
