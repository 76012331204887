import React from 'react';
import classes from './Notifications.module.css';
import { useNotifications } from '../../context/NotificationContext';

function Notification({ notification }) {
  if (notification.type === 'error') {
    return (
      <div className={classes['error-notification-container']}>
        <p className={classes['error-notification-message']}>
          {notification.textContent}
        </p>
      </div>
    );
  } else {
    return (
      <div className={classes['success-notification-container']}>
        <p className={classes['success-notification-message']}>
          {notification.textContent}
        </p>
      </div>
    );
  }
}

function Notifications() {
  const { notifications } = useNotifications();

  return notifications.length === 0 ? null : (
    <div className={classes['notifications-container']}>
      {notifications.map((notification) => (
        <Notification key={notification.id} notification={notification} />
      ))}
    </div>
  );
}

export default Notifications;
