import React from 'react';
import classes from './Button.module.css';

function Button(props) {
  const { type = 'primary' } = props;

  return (
    <button className={`${classes['button']} ${classes[type]}`} {...props}>
      {props.children}
    </button>
  );
}

export default Button;
