import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';

const NotificationContext = createContext({
  showNotification: function (_type, _textContent, _timeout) {},
  notifications: [],
});

const NotificationProvider = function ({ children }) {
  const [notifications, setNotifications] = useState([]);

  const closeNotification = function (notificationId, timeout) {
    setTimeout(function () {
      setNotifications((notifications) =>
        notifications.filter((el) => el.id !== notificationId)
      );
    }, timeout);
  };

  const showNotification = useCallback(function (
    type = 'error',
    textContent = '',
    timeout = 3000
  ) {
    const newNotification = {
      id: uuidv4(),
      type,
      textContent,
      timeout,
    };

    setNotifications((notifications) => {
      const updatedNotifications = [...notifications];
      updatedNotifications.push(newNotification);

      closeNotification(newNotification.id, timeout);

      return updatedNotifications;
    });
  },
  []);

  const value = useMemo(
    function () {
      return {
        showNotification,
        notifications,
      };
    },
    [showNotification, notifications]
  );
  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

const useNotifications = function () {
  const context = useContext(NotificationContext);
  if (context === undefined)
    throw new Error(
      'NotificationContext was used outside of NotificationProvider'
    );
  return context;
};

export { NotificationProvider, useNotifications };
