import React from 'react';
import classes from './GetStarted.module.css';
import GetStartedImage from '../../../../assets/get-started.png';
import CheckIcon from '../../../../assets/check.png';
import Button from '../../../../components/Button/Button';

function GetStarted({ setShowGetStartedPage }) {
  return (
    <div className={classes['get-started-container']}>
      <img
        className={classes['get-started-image']}
        src={GetStartedImage}
        alt="Get Started"
      />

      <nav>
        <h2 className={classes['debt-text']}>
          Hey👋 Let’s see how much debt we can payoff for you!
        </h2>

        <ul>
          <li className={classes['li']}>
            <img src={CheckIcon} alt="Check" />
            <p>No Credit checks</p>
          </li>
          <li className={classes['li']}>
            <img src={CheckIcon} alt="Check" />
            <p>About 5 mins to complete</p>
          </li>
          <li className={classes['li']}>
            <img src={CheckIcon} alt="Check" />
            <p>Commitment free offers</p>
          </li>
          <li className={classes['li']}>
            <img src={CheckIcon} alt="Check" />
            <p>No documents needed</p>
          </li>
        </ul>
      </nav>

      <div className={classes['get-started-button-container']}>
        <Button onClick={() => setShowGetStartedPage(false)} type="primary">
          Get Started
        </Button>
      </div>
    </div>
  );
}

export default GetStarted;
