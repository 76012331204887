export const isInputValid = function (
  value = '',
  minLength = 1,
  isEmail,
  validationRequirements = {}
) {
  let isValid = true;

  if (isEmail) {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    isValid = isValid && emailPattern.test(value);
  }

  if (minLength) {
    isValid = isValid && value?.length >= minLength;
  }

  if (value?.length > 0) {
    if (validationRequirements.min !== undefined) {
      isValid =
        isValid &&
        !isNaN(value) &&
        parseFloat(value) >= validationRequirements.min;
    }

    if (validationRequirements.max !== undefined) {
      isValid =
        isValid &&
        !isNaN(value) &&
        parseFloat(value) <= validationRequirements.max;
    }
  }

  return isValid;
};

export const getFormattedNumber = (number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const generateCSVLink = function (csvContent, linkName) {
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = linkName;

  document.body.appendChild(link);

  link.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);

    document.body.removeChild(link);
  }, 100);
};

export const limitString = (string = '', limit) => {
  if (string.length <= limit) return string;

  return string.substring(0, limit) + '...';
};
