import React, { useState } from 'react';
import classes from './RejectUserOnboardingSurveyOfferStatusModal.module.css';
import Modal from '../../../../../../components/Modal/Modal';
import Button from '../../../../../../components/Button/Button';
import { rejectUserOnboardingSurveyOfferStatusRequest } from '../../../../../../services/userOnboardingSurveyOfferStatus';
import { useNotifications } from '../../../../../../context/NotificationContext';

const REJECTION_REASONS = [
  'Income below threshold',
  'Not enough work history',
  'Credit score below threshold',
  'Too many missed payments',
  'Recent bankruptcy',
  'Amount requested is too high',
  'Suspected fraud',
];

function RejectUserOnboardingSurveyOfferStatusModal({
  closeModal,
  rejectUserOnboardingSurveyOfferStatusModalState,
  invalidateUsersBasedOnOnboardingSurveyOfferStatuses,
}) {
  const { user } = rejectUserOnboardingSurveyOfferStatusModalState;

  const { showNotification } = useNotifications();

  const [selectedReasons, setSelectedReasons] = useState([]);
  const [rejectingStatus, setRejectingStatus] = useState(false);

  const selectReasonHandler = function (reason) {
    setSelectedReasons((reasons) => {
      if (reasons.includes(reason))
        return reasons.filter((el) => el !== reason);

      return [...reasons, reason];
    });
  };

  const rejectUserOnboardingSurveyOfferStatusHandler = async function () {
    setRejectingStatus(true);

    try {
      const requestBody = {
        rejectionReasons: selectedReasons,
      };
      await rejectUserOnboardingSurveyOfferStatusRequest(user._id, requestBody);

      invalidateUsersBasedOnOnboardingSurveyOfferStatuses();

      showNotification('success', 'User Rejected successfully!', 1000);

      setTimeout(() => {
        setRejectingStatus(false);
        closeModal();
      }, 1000);
    } catch (err) {
      setRejectingStatus(false);

      showNotification('error', err, 3000);
    }
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={rejectingStatus}
            type="danger"
            style={{ width: 200 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            disabled={rejectingStatus}
            onClick={rejectUserOnboardingSurveyOfferStatusHandler}
            style={{ width: 200 }}
          >
            Reject
          </Button>
        </div>
      }
      closeModal={closeModal}
      title={`Reject Offer --- User: ${user.fullName}`}
    >
      <div>
        {REJECTION_REASONS.map((reason) => (
          <div key={reason} className={classes['reason-container']}>
            <label>
              <input
                type="checkbox"
                checked={selectedReasons.includes(reason)}
                onChange={() => selectReasonHandler(reason)}
              />
              {reason}
            </label>
          </div>
        ))}
      </div>
    </Modal>
  );
}

export default RejectUserOnboardingSurveyOfferStatusModal;
