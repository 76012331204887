import moment from 'moment';
import classes from './SeeCanceledOfferDetailsModal.module.css';
import React from 'react';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';

const CANCEL_REASONS = [
  'I am no longer seeking funding',
  'The terms are too complicated',
  'I prefer fixed payments each month over a % of my income',
  'Other: (allow the user to type this in)',
];

function SeeCanceledOfferDetailsModal({ offer, closeModal }) {
  const renderDetailsHandler = function () {
    return (
      <>
        <div className={classes['offer-information-container']}>
          <div className={classes['hipo-text-container']}>
            <h3 className={classes['hipo-text']}>HiPo</h3>
          </div>

          <div className={classes['info-container']}>
            <div className={classes['info-section-container']}>
              <p>Requested Amount</p>
              <p>{offer.offerAmount}</p>
            </div>

            <div className={classes['info-section-container']}>
              <p>Estimated Monthly Payment</p>
              <p>{offer.estimatedMonthlyPayment}</p>
            </div>

            <div className={classes['info-section-container']}>
              <p>Percent of Monthly Income</p>
              <p>{offer.monthlyPercentIncome}%</p>
            </div>

            <div className={classes['info-section-container']}>
              <p>Pay Off In</p>
              <p>{moment(offer.payOffIn).format('MMMM Do YYYY')}</p>
            </div>
          </div>
        </div>
        <div className={classes['rejection-reasons-container']}>
          <h3 className={classes['rejection-reasons-text']}>
            Canceled Reasons
          </h3>
          {offer.canceledReasons.map((reason, i) => {
            return (
              <p key={reason} className={classes['rejection-reason']}>
                {i + 1}. {reason}
              </p>
            );
          })}

          {offer.otherText && (
            <p className={classes['other-text-reason']}>
              {offer.canceledReasons.length + 1}. {offer.otherText}
            </p>
          )}
        </div>
      </>
    );
  };

  return (
    <Modal
      style={{ height: 550 }}
      footer={
        <div>
          <Button type="danger" style={{ width: 200 }} onClick={closeModal}>
            Close
          </Button>
        </div>
      }
      closeModal={closeModal}
      title="See Details"
    >
      <div className={classes['details-container']}>
        {renderDetailsHandler()}
      </div>
    </Modal>
  );
}

export default SeeCanceledOfferDetailsModal;
