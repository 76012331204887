import React from 'react';
import classes from './ActiveOffer.module.css';
import { useUserInformation } from '../../../../context/UserInformationContext';
import moment from 'moment';

function ActiveOffer() {
  const { myOfferInformation } = useUserInformation();

  return (
    <div className={classes['active-offer-page-container']}>
      <h3>Your Offer Is Active</h3>
      <div className={classes['offer-information-container']}>
        <div className={classes['hipo-text-container']}>
          <h3 className={classes['hipo-text']}>HiPo</h3>
        </div>

        <div className={classes['info-container']}>
          <div className={classes['info-section-container']}>
            <p>Offer Amount</p>
            <p>{myOfferInformation.offerAmount} USD</p>
          </div>

          <div className={classes['info-section-container']}>
            <p>Estimated Monthly Payment</p>
            <p>{myOfferInformation.estimatedMonthlyPayment}</p>
          </div>

          <div className={classes['info-section-container']}>
            <p>Percent of Monthly Income</p>
            <p>{myOfferInformation.monthlyPercentIncome}%</p>
          </div>

          <div className={classes['info-section-container']}>
            <p>Pay Off In</p>
            <p>{moment(myOfferInformation.payOffIn).format('MMMM Do YYYY')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveOffer;
