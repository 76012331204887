import React from 'react';
import classes from './AdminSidebar.module.css';
import { useAdminPanel } from '../../../../context/AdminPanelContext';

function AdminSidebar() {
  const {
    ADMIN_SIDEBAR_SECTIONS,
    selectedSection,
    setSelectedSection,
  } = useAdminPanel();

  return (
    <div className={classes['admin-sidebar-container']}>
      {Object.values(ADMIN_SIDEBAR_SECTIONS).map((section) => {
        return (
          <div
            onClick={() => setSelectedSection(section)}
            key={section}
            className={`${classes['section-container']} ${
              section === selectedSection &&
              classes['selected-section-container']
            }`}
          >
            <p
              className={`${classes['section-text']} ${
                selectedSection === section && classes['selected-section-text']
              }`}
            >
              {section}
            </p>
          </div>
        );
      })}
    </div>
  );
}

export default AdminSidebar;
