import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { getMyOfferRequest } from '../services/userOffer';
import { useAuthentication } from './AuthenticationContext';
import { useNotifications } from './NotificationContext';
import { getMyOnboardingSurveyOfferStatusRequest } from '../services/userOnboardingSurveyOfferStatus';
import { getMyCanceledOffersRequest } from '../services/canceledUserOffer';
import { getMyRejectedOffersRequest } from '../services/rejectedUserOffer';

const UserInformationContext = createContext({
  userInformationLoading: true,
  myOfferInformation: null,
  myOnboardingSurveyOfferStatusInformation: null,
  getMyInformationHandler: () => {},
  myCanceledOffers: [],
  myRejectedOffers: [],
});

function UserInformationProvider({ children }) {
  const { isLoggedIn } = useAuthentication();
  const { showNotification } = useNotifications();

  const [userInformationLoading, setUserInformationLoading] = useState(true);
  const [
    myOnboardingSurveyOfferStatusInformation,
    setMyOnboardingSurveyOfferStatusInformation,
  ] = useState(null);
  const [myOfferInformation, setMyOfferInformation] = useState(null);
  const [myCanceledOffers, setMyCanceledOffers] = useState([]);
  const [myRejectedOffers, setMyRejectedOffers] = useState([]);

  const getMyOfferInformationHandler = useCallback(async function () {
    try {
      const { data } = await getMyOfferRequest();
      setMyOfferInformation(data);
    } catch (err) {
      throw err;
    }
  }, []);

  const getMyOnboardingSurveyOfferStatusHandler = useCallback(
    async function () {
      try {
        const { data } = await getMyOnboardingSurveyOfferStatusRequest();
        setMyOnboardingSurveyOfferStatusInformation(data);
      } catch (err) {
        throw err;
      }
    },
    []
  );

  const getMyCanceledOffersHandler = useCallback(async function () {
    try {
      const { data } = await getMyCanceledOffersRequest();
      setMyCanceledOffers(data);
    } catch (err) {
      throw err;
    }
  }, []);

  const getMyRejectedOffersHandler = useCallback(async function () {
    try {
      const { data } = await getMyRejectedOffersRequest();
      setMyRejectedOffers(data);
    } catch (err) {
      throw err;
    }
  }, []);

  const getMyInformationHandler = useCallback(
    async function () {
      try {
        await Promise.all([
          getMyOnboardingSurveyOfferStatusHandler(),
          getMyOfferInformationHandler(),
          getMyCanceledOffersHandler(),
          getMyRejectedOffersHandler(),
        ]);

        setUserInformationLoading(false);
      } catch (err) {
        showNotification('error', err, 3000);
      }
    },
    [
      getMyOfferInformationHandler,
      getMyOnboardingSurveyOfferStatusHandler,
      getMyCanceledOffersHandler,
      getMyRejectedOffersHandler,
      showNotification,
    ]
  );

  useEffect(
    function () {
      if (!isLoggedIn) return;

      getMyInformationHandler();
    },
    [isLoggedIn, getMyInformationHandler]
  );

  const value = useMemo(() => {
    return {
      userInformationLoading,
      myOfferInformation,
      myOnboardingSurveyOfferStatusInformation,
      getMyInformationHandler,
      myCanceledOffers,
      myRejectedOffers,
    };
  }, [
    userInformationLoading,
    myOfferInformation,
    myOnboardingSurveyOfferStatusInformation,
    getMyInformationHandler,
    myCanceledOffers,
    myRejectedOffers,
  ]);

  return (
    <UserInformationContext.Provider value={value}>
      {children}
    </UserInformationContext.Provider>
  );
}

const useUserInformation = function () {
  const context = useContext(UserInformationContext);
  if (context === undefined)
    throw new Error(
      'UserInformationContext was used outside of UserInformationProvider'
    );
  return context;
};

export { useUserInformation, UserInformationProvider };
