import React from 'react';
import classes from './CanceledOffersReasonsModal.module.css';
import Modal from '../../../../../../components/Modal/Modal';
import Button from '../../../../../../components/Button/Button';

function CanceledOffersReasonsModal({
  canceledOfferReasonsModalState,
  closeModal,
}) {
  const { canceledReasons, otherText } = canceledOfferReasonsModalState;

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button type="danger" style={{ width: 200 }} onClick={closeModal}>
            Close
          </Button>
        </div>
      }
      closeModal={closeModal}
      title={`Canceled Reasons`}
    >
      <div>
        {canceledReasons.map((reason, i) => (
          <div key={reason} className={classes['reason-container']}>
            <label>
              {i + 1}. {reason}
            </label>
          </div>
        ))}

        {otherText && (
          <label>
            {canceledReasons.length + 1}. {otherText}
          </label>
        )}
      </div>
    </Modal>
  );
}

export default CanceledOffersReasonsModal;
