import React from 'react';
import classes from './Modal.module.css';

function Modal(props) {
  const overlayClickHandler = (e) => {
    // const element = e.target.getAttribute('id');
    // if (element === 'overlay') props.closeModal();
  };

  return (
    <div
      id="overlay"
      onClick={overlayClickHandler}
      className={classes['overlay']}
    >
      <div style={props.style || {}} id="modal" className={classes['modal']}>
        <h3 className={classes['title']}>{props.title}</h3>
        <div className={classes['horizontal-line']}></div>

        <div className={classes['modal-content']}>{props.children}</div>
        <div className={classes['modal-footer']}>{props.footer}</div>
      </div>
    </div>
  );
}

export default Modal;
