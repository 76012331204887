import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/users/`;

export const SIGNUP_ENDPOINT = BASE_ENDPOINT + 'signup';
export const LOGIN_ENDPOINT = BASE_ENDPOINT + 'login';
export const GET_MY_PROFILE_ENDPOINT = BASE_ENDPOINT + 'profile';

export const signupRequest = async function (requestBody) {
  try {
    const { data } = await axios.post(SIGNUP_ENDPOINT, requestBody);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const loginRequest = async function (requestBody) {
  try {
    const { data } = await axios.post(LOGIN_ENDPOINT, requestBody);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getMyProfileRequest = async function () {
  try {
    const { data } = await axios.get(GET_MY_PROFILE_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
