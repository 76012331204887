import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/users`;

export const GET_ALL_USERS_ENDPOINT = BASE_ENDPOINT;

export const getAllUsersRequest = async function (page, limit, searchValue) {
  try {
    const { data } = await axios.get(
      `${GET_ALL_USERS_ENDPOINT}?page=${page}&limit=${limit}&searchValue=${searchValue}`
    );
    return data;
  } catch (error) {
    throw handleError(error);
  }
};
