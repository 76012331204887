import React, { useState } from 'react';
import classes from './Landing.module.css';
import LineImage from '../../assets/line.png';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Logo from '../../components/Logo/Logo';
import Select from '../../components/Select/Select';
import InvestmentIcon from '../../assets/investment-data.png';
import CheckIcon from '../../assets/check.png';
import BellRingIcon from '../../assets/bell-ring.png';
import Footer from '../../components/Footer/Footer';
import ConfettiIcon from '../../assets/confetti.png';
import { getFormattedNumber } from '../../utils/helpers';
import AngleImage from '../../assets/angle.png';
import AngleImage2 from '../../assets/angle-2.png';
import { useNotifications } from '../../context/NotificationContext';
import { createEstimatedLifetimeEarningsQuestionnaireRequest } from '../../services/estimatedLifetimeEarningsQuestionnaire';
import { subscribeEmailRequest } from '../../services/emailSubscription';
import { Link } from 'react-router-dom';

function Landing() {
  const { showNotification } = useNotifications();
  const [age, setAge] = useState('');
  const [ageTouched, setAgeTouched] = useState(false);

  const [moneyValue, setMoneyValue] = useState('');
  const [moneyValueTouched, setMoneyValueTouched] = useState(false);

  const [highestLevelOfEducation, setHighestLevelOfEducation] = useState();
  const [liveOrWorkInMajorCity, setLiveOrWorkInMajorCity] = useState();

  const [emailSubscription, setEmailSubscription] = useState('');
  const [emailSubscriptionTouched, setEmailSubscriptionTouched] = useState(
    false
  );

  const [generatedNumber, setGeneratedNumber] = useState();
  const [waitlistEmail, setWaitlistEmail] = useState('');
  const [waitlistEmailTouched, setWaitlistEmailTouched] = useState(false);

  const ageChangeHandler = function (e) {
    setAge(e.target.value);
    setAgeTouched(true);
  };

  const moneyValueChangeHandler = function (e) {
    setMoneyValue(e.target.value);
    setMoneyValueTouched(true);
  };

  const emailSubscriptionChangeHandler = function (e) {
    setEmailSubscription(e.target.value);
    setEmailSubscriptionTouched(true);
  };

  const waitlistEmailChangeHandler = function (e) {
    setWaitlistEmail(e.target.value);
    setWaitlistEmailTouched(true);
  };

  const generateNumberHandler = function () {
    const educationMultiplications = {
      'High School': 1.1,
      Undergraduate: 1.3,
      Graduate: 1.5,
      Doctorate: 1.4,
    };
    const cityMultiplication = {
      Yes: 1.2,
      No: 1,
    };

    const education = educationMultiplications[highestLevelOfEducation.value];
    const city = cityMultiplication[liveOrWorkInMajorCity.value];

    const generatedNumber =
      (68 - parseInt(age)) * parseFloat(moneyValue) * education * city;

    setGeneratedNumber(generatedNumber);

    return generatedNumber;
  };

  const waitlistHandler = async function () {
    try {
      const questions = [
        'What’s your age?',
        'What’s your highest level of education?',
        'How much money will you make this year?',
        'Do you live or work in a major city (NYC, London etc.)?',
      ];
      const answers = [
        age,
        highestLevelOfEducation.value,
        moneyValue,
        liveOrWorkInMajorCity.value,
      ];
      const requestBody = {
        questions,
        answers,
        generatedLifetimeEarnings: generatedNumber,
        email: waitlistEmail,
      };

      await createEstimatedLifetimeEarningsQuestionnaireRequest(requestBody);

      setWaitlistEmail('');
      setWaitlistEmailTouched(false);

      showNotification('success', 'Waitlist reserved!', 3000);
    } catch (err) {
      if (err.startsWith('E11000')) {
        showNotification('error', 'Email reserved!', 3000);
      } else showNotification('error', err, 3000);
    }
  };

  const subscribeEmailHandler = async function () {
    try {
      const requestBody = {
        email: emailSubscription,
      };
      await subscribeEmailRequest(requestBody);

      setEmailSubscription('');
      setEmailSubscriptionTouched(false);

      showNotification('success', 'Email subscribed successfully!', 3000);
    } catch (err) {
      if (err.startsWith('E11000')) {
        showNotification('error', 'Email reserved!', 3000);
      } else showNotification('error', err, 3000);
    }
  };

  const ageInputStyle = {};
  const moneyValueInputStyle = {};
  if (
    ageTouched &&
    (!age || isNaN(age) || parseInt(age) < 10 || parseInt(age) > 67)
  ) {
    ageInputStyle['border'] = '1px solid red';
  }
  if (
    moneyValueTouched &&
    (!moneyValue || isNaN(moneyValue) || parseInt(moneyValue) < 1)
  ) {
    moneyValueInputStyle['border'] = '1px solid red';
  }

  const emailSubscriptionInputStyle = {
    padding: '1.2rem',
    minWidth: 300,
  };
  const waitlistEmailInputStyle = {
    padding: '1.2rem',
    minWidth: 300,
  };
  if (
    emailSubscriptionTouched &&
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailSubscription) === false
  ) {
    emailSubscriptionInputStyle['border'] = '1px solid red';
  }

  if (
    waitlistEmailTouched &&
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(waitlistEmail) === false
  ) {
    waitlistEmailInputStyle['border'] = '1px solid red';
  }

  const isGenerateButtonEnabled =
    !(!age || isNaN(age) || parseInt(age) < 10 || parseInt(age) > 67) &&
    !(!moneyValue || isNaN(moneyValue) || parseInt(moneyValue) < 1) &&
    highestLevelOfEducation &&
    liveOrWorkInMajorCity;

  const waitlistButtonEnabled =
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(waitlistEmail) === true;
  const subscribeEmailButtonEnabled =
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailSubscription) === true;

  return (
    <div className={classes['landing-container']}>
      <header className={classes['header']}>
        <Logo />

        <div className={classes['login-signup-container']}>
          <Link to="/signup">
            <Button type="primary">Sign up</Button>
          </Link>
          <Link to="/login">
            <Button type="secondary">Log in</Button>
          </Link>
        </div>
      </header>

      {generatedNumber ? (
        <main className={classes['generated-container']}>
          <img
            src={AngleImage}
            alt="Angle"
            className={classes['angle-image']}
          />
          <img
            src={AngleImage2}
            alt="Angle"
            className={classes['angle-image-2']}
          />
          <img
            alt="Confetti"
            src={ConfettiIcon}
            className={classes['confetti-icon']}
          />
          <p className={classes['estimated-text']}>
            Estimated Lifetime Earnings
          </p>
          <h2 className={classes['generated-value']}>
            $ {getFormattedNumber(parseFloat(generatedNumber).toFixed(2))}
          </h2>

          <p className={classes['generated-description-text']}>
            In 2024, you could access up to $
            {getFormattedNumber((parseFloat(generatedNumber) / 10).toFixed(2))}{' '}
            through HiPo! To save your spot in line, enter your email below.
          </p>

          <div className={classes['waitlist-email-container']}>
            <Input
              placeholder="Enter email"
              value={waitlistEmail}
              onChange={waitlistEmailChangeHandler}
              style={waitlistEmailInputStyle}
            />
            <Button
              style={{ width: '100%' }}
              type="primary"
              onClick={waitlistHandler}
              disabled={!waitlistButtonEnabled}
            >
              Save my spot
            </Button>
          </div>
        </main>
      ) : (
        <main className={classes['main-container']}>
          <img
            src={AngleImage}
            alt="Angle"
            className={classes['angle-image']}
          />
          <img
            src={AngleImage2}
            alt="Angle"
            className={classes['angle-image-2']}
          />

          <div className={classes['description-container']}>
            <h2 className={classes['how-much-text']}>
              How much more <span>$$$</span> will you earn in your lifetime?
            </h2>
            <img src={LineImage} alt="Discover" />
          </div>

          <div className={classes['questions-container']}>
            <Input
              placeholder="What’s your age?"
              type="number"
              value={age}
              onChange={ageChangeHandler}
              style={ageInputStyle}
            />
            <Select
              setSelectedOption={setHighestLevelOfEducation}
              selectedOption={highestLevelOfEducation}
              placeholder="What’s your highest level of education?"
              options={[
                { value: 'High School' },
                { value: 'Undergraduate' },
                { value: 'Graduate' },
                { value: 'Doctorate' },
              ]}
            />
            <Input
              placeholder="How much money will you make this year?"
              value={moneyValue}
              type="number"
              onChange={moneyValueChangeHandler}
              style={moneyValueInputStyle}
            />

            <Select
              selectedOption={liveOrWorkInMajorCity}
              setSelectedOption={setLiveOrWorkInMajorCity}
              placeholder="Do you live or work in a major city (NYC, London etc.)?"
              options={[{ value: 'Yes' }, { value: 'No' }]}
            />

            <Button
              disabled={!isGenerateButtonEnabled}
              style={{ marginTop: 20, width: '100%' }}
              type="primary"
              onClick={generateNumberHandler}
            >
              Generate
            </Button>
          </div>
        </main>
      )}
      <section className={classes['how-it-works-section']}>
        <div className={classes['how-it-works-container']}>
          <div className={classes['how-it-works-box']}>
            <h2 className={classes['how-it-works-text']}>What's HiPo?</h2>
          </div>

          <div className={classes['how-it-works-description-container']}>
            <div className={classes['how-it-works-description-box']}>
              <div className={classes['orange-circle']}></div>
              <h3>Earnings Calculator</h3>
              <p>
                HiPo let's you see how much you're projected to make over your
                lifetime
              </p>
            </div>

            <div className={classes['how-it-works-description-box']}>
              <div className={classes['purple-circle']}></div>
              <h3>Cash Today</h3>
              <p>Get access to 10% of your lifetime earning potential, today</p>
            </div>

            <div className={classes['how-it-works-description-box']}>
              <div className={classes['blue-circle']}></div>
              <h3>Debt Freedom</h3>
              <p>
                $ from HiPo is an investment in you, not a debt. Only repay if
                you make above our minimum threshold
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={classes['are-you-hipo-section']}>
        <img
          src={InvestmentIcon}
          alt="HiPo"
          className={classes['investment-icon']}
        />

        <div className={classes['are-you-hipo-container']}>
          <h2 className={classes['are-you-hipo-heading']}>Are you a HiPo?</h2>

          <div className={classes['checkmarks-container']}>
            <div className={classes['checkmark-container']}>
              <img
                src={CheckIcon}
                alt="Checked"
                className={classes['check-icon']}
              />
              <p>Looking for $ to pursue an opportunity</p>
            </div>

            <div className={classes['checkmark-container']}>
              <img
                src={CheckIcon}
                alt="Checked"
                className={classes['check-icon']}
              />
              <p>Motivated to increase your earning potential</p>
            </div>

            <div className={classes['checkmark-container']}>
              <img
                src={CheckIcon}
                alt="Checked"
                className={classes['check-icon']}
              />
              <p>Don't want to take on debt through a credit card or loan</p>
            </div>
            <div className={classes['checkmark-container']}>
              <img
                src={CheckIcon}
                alt="Checked"
                className={classes['check-icon']}
              />
              <p>Want affordable and flexible payments based on your income</p>
            </div>
          </div>
        </div>
      </section>

      <section className={classes['subscribe-section']}>
        <img
          src={BellRingIcon}
          alt="Subscribe"
          className={classes['bell-icon']}
        />
        <div>
          <h2 className={classes['subscribe-heading']}>
            Save your spot to receive an investment from HiPo!
          </h2>
          <p className={classes['subscribe-description']}>
            If you're a person with potential looking for investment to pay for
            life enhancing opportunities, save your spot below.
          </p>
          <div className={classes['email-subscribe-container']}>
            <Input
              style={emailSubscriptionInputStyle}
              value={emailSubscription}
              onChange={emailSubscriptionChangeHandler}
              placeholder="Enter email"
            />

            <Button
              onClick={subscribeEmailHandler}
              style={{ width: '100%' }}
              type="primary"
              disabled={!subscribeEmailButtonEnabled}
            >
              Save my spot
            </Button>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Landing;
