import React from 'react';
import classes from './AdminPanel.module.css';
import AdminSidebar from './components/AdminSidebar/AdminSidebar';
import { useAdminPanel } from '../../context/AdminPanelContext';
import OnboardingSurveyData from './components/OnboardingSurveyData/OnboardingSurveyData';
import EmailSubscriptions from './components/EmailSubscriptions/EmailSubscriptions';
import LifeTimeEarnings from './components/LifeTimeEarnings/LifeTimeEarnings';
import AllUsers from './components/AllUsers/AllUsers';
import UserOffer from './components/UserOffer/UserOffer';
import CanceledOffers from './components/CanceledOffers/CanceledOffers';
import RejectedOffers from './components/RejectedOffers/RejectedOffers';

function AdminPanel() {
  const { selectedSection, ADMIN_SIDEBAR_SECTIONS } = useAdminPanel();

  const SECTION_COMPONENTS_MAPPING = {
    [ADMIN_SIDEBAR_SECTIONS.ONBOARDING_SURVEY]: <OnboardingSurveyData />,
    [ADMIN_SIDEBAR_SECTIONS.EMAIL_SUBSCRIPTIONS]: <EmailSubscriptions />,
    [ADMIN_SIDEBAR_SECTIONS.LIFETIME_EARNINGS]: <LifeTimeEarnings />,
    [ADMIN_SIDEBAR_SECTIONS.ALL_USERS]: <AllUsers />,
    [ADMIN_SIDEBAR_SECTIONS.USER_OFFER]: <UserOffer />,
    [ADMIN_SIDEBAR_SECTIONS.CANCELED_OFFERS]: <CanceledOffers />,
    [ADMIN_SIDEBAR_SECTIONS.REJECTED_OFFERS]: <RejectedOffers />,
  };

  return (
    <div className={classes['admin-panel-container']}>
      <AdminSidebar />

      {SECTION_COMPONENTS_MAPPING[selectedSection]}
    </div>
  );
}

export default AdminPanel;
