import React from 'react';
import classes from './SeeRejectedOfferDetailsModal.module.css';
import Modal from '../../../components/Modal/Modal';
import Button from '../../../components/Button/Button';
import moment from 'moment';

function SeeRejectedOfferDetailsModal({ offer, closeModal }) {
  const renderDetailsHandler = function () {
    return (
      <>
        <div className={classes['offer-information-container']}>
          <div className={classes['hipo-text-container']}>
            <h3 className={classes['hipo-text']}>HiPo</h3>
          </div>

          <div className={classes['info-container']}>
            <div className={classes['info-section-container']}>
              <p>Requested Amount</p>
              <p>{offer.requestedAmount}</p>
            </div>

            <div className={classes['info-section-container']}>
              <p>Rejection Date</p>
              <p>{moment(offer.createdAt).format('MMMM Do YYYY')}</p>
            </div>
          </div>
        </div>
        <div className={classes['rejection-reasons-container']}>
          <h3 className={classes['rejection-reasons-text']}>
            Rejection Reasons
          </h3>
          {offer.rejectionReasons.map((reason, i) => {
            return (
              <p key={reason} className={classes['rejection-reason']}>
                {i + 1}. {reason}
              </p>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <Modal
      style={{ height: 550 }}
      footer={
        <div>
          <Button type="danger" style={{ width: 200 }} onClick={closeModal}>
            Close
          </Button>
        </div>
      }
      closeModal={closeModal}
      title="See Details"
    >
      <div className={classes['details-container']}>
        {renderDetailsHandler()}
      </div>
    </Modal>
  );
}

export default SeeRejectedOfferDetailsModal;

