import React from 'react';
import classes from './RejectedOffers.module.css';
import { generateCSVLink } from '../../../../utils/helpers';
import { useNotifications } from '../../../../context/NotificationContext';
import Button from '../../../../components/Button/Button';
import { getRejectedOnboardingSurveyInformationForAllUsersRequest } from '../../../../services/rejectedUserOnboardingSurvey';

const questions = [
  'What is your full legal name?',
  'What is your birthdate?',
  'Please enter in your current full address',
  'If you attended a University and have been awarded a Bachelors degree, please enter the name of your University. ',
  'If you received a Bachelors degree, what was your degree awarded in (ie: Biology)',
  'If you received a Bachelors degree, what was your GPA (ie. 3.0)',
  'If you received a Bachelors degree, what year did you graduate?',
  'Please enter in your employment history',
  'What is your gross annual salary (before taxes)?',
  'If you have credit card debt, what is your total credit card balance?',
  'If you entered an amount in the previous question, what is your total monthly payment to all credit card accounts? ',
  'If you have other debts besides for credit card debt (car loan, small business loan, personal loan, school loans etc.) please enter your total debt.',
  'If you entered an amount in the previous question, what is your total monthly payment for all your combined debts (not including credit cards).',
  'Have you ever filed for bankruptcy before?',
  'How many credit card accounts do you have?',
  'How many times have you been late on a credit card payment in the last 2 years (please estimate to the best of your ability).',
  'What is your credit score?',
  'Do you own your house?',
  'What is your current monthly rent or mortgage payment?',
  'What is the total amount you are requesting from HiPo? For example, this amount may be the full amount of your credit card debt or only a partial amount?',
];

const shortQuestions = [
  'Full_name?',
  'Birthdate',
  'Address',
  'University ',
  'Degree',
  'GPA',
  'Graduation_year',
  'Employment',
  'Income',
  'Total_credit_card_debt',
  'Monthly_credit_card_payment',
  'Other_debt',
  'Monthly_other_debt_payment',
  'Bankruptcy',
  'Number_credit_cards',
  'Number_missed_payments',
  'Credit_score',
  'Own_house',
  'Monthly_house_payment',
  'HiPo_amount',
];

function RejectedOffers() {
  const { showNotification } = useNotifications();

  function generateEmploymentHistoryCSV(employmentHistory) {
    const headerRowsMapping = {
      'Job Title': 'jobTitle',
      'Company Name': 'companyName',
      'Currently Employed': 'currentlyEmployed',
      'Start Date': 'startDate',
      'End Date': 'endDate',
    };

    const csvHeader = [
      'Job Title',
      'Company Name',
      'Currently Employed',
      'Start Date',
      'End Date',
    ];

    if (employmentHistory.length === 0) {
      return { csvHeader, csvRows: new Array(csvHeader.length).fill('') };
    }

    let sortedEmployment;

    if (employmentHistory.length === 1) {
      sortedEmployment = employmentHistory[0];
    } else {
      const index = employmentHistory.findIndex((el) => el.currentlyEmployed);
      if (index !== -1) {
        sortedEmployment = employmentHistory[index];
      } else {
        employmentHistory.sort(
          (a, b) => new Date(b.endDate) - new Date(a.endDate)
        );
        sortedEmployment = employmentHistory;
      }
    }

    const csvRows = [];
    csvHeader.forEach((el) => {
      if (headerRowsMapping[el] === 'currentlyEmployed')
        csvRows.push(sortedEmployment.currentlyEmployed ? 'Yes' : 'No');
      else csvRows.push(sortedEmployment[headerRowsMapping[el]]);
    });

    return { csvHeader, csvRows };
  }

  const generateCSVContent = function (pivotData) {
    let csvContent = [
      'User Id',
      'Version',
      'FullName',
      'Email',
      'Requested Amount (*User)',
      'Status,',
    ].join(',');

    const uniqueQuestions = new Set();

    pivotData.forEach((el) => {
      el.onboardingSurvey.forEach((entry) => {
        uniqueQuestions.add(entry.question);
      });
    });

    const sortedQuestions = [...questions];

    csvContent += sortedQuestions
      .map((_question, i) => `"${shortQuestions[i]}"`)
      .join(',');

    csvContent += [
      ',Job Title',
      'Company Name',
      'Currently Employed',
      'Start Date',
      'End Date',
    ].join(',');

    csvContent += '\n';

    pivotData.forEach(
      ({ user, version, requestedAmount, onboardingSurvey }) => {
        let rowData = {
          'User Id': user._id,
          Version: version,
          FullName: user.fullName,
          Email: user.email,
          'Requested Amount (*User)': requestedAmount,
          Status: 'Rejected',
        };

        sortedQuestions.forEach((question) => {
          const entry = onboardingSurvey.find(
            (entry) => entry.question === question
          );

          rowData[question] = entry ? entry.answer : '';
        });

        const employmentHistoryData = onboardingSurvey.find(
          ({ question }) =>
            question === 'Please enter in your employment history'
        );

        if (employmentHistoryData?.employmentHistory) {
          const { csvHeader, csvRows } = generateEmploymentHistoryCSV(
            employmentHistoryData.employmentHistory
          );

          csvHeader.forEach((el, i) => {
            rowData[el] = csvRows[i];
          });
        }

        csvContent += Object.values(rowData).join(',') + '\n';
      }
    );

    return csvContent;
  };

  const exportRejectedOffersAsCSVFileHandler = async function () {
    try {
      const {
        data,
      } = await getRejectedOnboardingSurveyInformationForAllUsersRequest();

      const csvContent = generateCSVContent(data);

      generateCSVLink(csvContent, 'rejected_offers.csv');
    } catch (err) {
      showNotification('error', err, 3000);
    }
  };

  return (
    <>
      <div className={classes['all-users-page-container']}>
        <div className={classes['all-users-search-container']}>
          <h2>Rejected Offers</h2>

          <Button onClick={exportRejectedOffersAsCSVFileHandler}>
            Export CSV
          </Button>
        </div>
      </div>
    </>
  );
}

export default RejectedOffers;
