import React, { useState } from 'react';
import classes from './OfferInformation.module.css';
import { useUserInformation } from '../../../../../../context/UserInformationContext';
import moment from 'moment';
import Button from '../../../../../../components/Button/Button';
import CancelOfferModal from './components/CancelOfferModal/CancelOfferModal';
import AcceptOfferModal from './components/AcceptOfferModal/AcceptOfferModal';

function OfferInformation({ setShowOffer }) {
  const { myOfferInformation } = useUserInformation();

  const [showCancelOfferModal, setShowCancelOfferModal] = useState(false);
  const [showAcceptOfferModal, setShowAcceptOfferModal] = useState(false);

  const openCancelOfferModalHandler = function () {
    setShowCancelOfferModal(true);
  };

  const openAcceptOfferModalHandler = function () {
    setShowAcceptOfferModal(true);
  };

  if (!myOfferInformation) return null;

  return (
    <>
      {showCancelOfferModal && (
        <CancelOfferModal setShowCancelOfferModal={setShowCancelOfferModal} />
      )}

      {showAcceptOfferModal && (
        <AcceptOfferModal
          setShowAcceptOfferModal={setShowAcceptOfferModal}
          setShowOffer={setShowOffer}
        />
      )}

      <div className={classes['offer-information-container']}>
        <div className={classes['hipo-text-container']}>
          <h3 className={classes['hipo-text']}>HiPo</h3>
        </div>

        <div className={classes['info-container']}>
          <div className={classes['info-section-container']}>
            <p>Estimated Monthly Payment</p>
            <p>{myOfferInformation.estimatedMonthlyPayment}</p>
          </div>

          <div className={classes['info-section-container']}>
            <p>Percent of Monthly Income</p>
            <p>{myOfferInformation.monthlyPercentIncome}%</p>
          </div>

          <div className={classes['info-section-container']}>
            <p>Pay Off In</p>
            <p>{moment(myOfferInformation.payOffIn).format('MMMM Do YYYY')}</p>
          </div>
        </div>
      </div>

      <p>
        Your monthly payment is based on a % of your income. The % you are
        required to pay decreases each time you pay more than your base payment.
      </p>

      <div className={classes['actions-container']}>
        <Button onClick={openCancelOfferModalHandler} type="danger">
          Cancel
        </Button>
        <Button onClick={openAcceptOfferModalHandler}>Accept</Button>
      </div>
    </>
  );
}

export default OfferInformation;
