import React, { useState } from 'react';
import classes from './MyDashboard.module.css';
import { useAuthentication } from '../../context/AuthenticationContext';
import UserOnboardingSurvey from './components/UserOnboardingSurvey/UserOnboardingSurvey';
import Congrats from '../../components/Congrats/Congrats';
import { useUserInformation } from '../../context/UserInformationContext';
import Button from '../../components/Button/Button';
import YourOffer from './components/YourOffer/YourOffer';
import ActiveOffer from './components/ActiveOffer/ActiveOffer';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

function MyDashboard() {
  const { profile } = useAuthentication();
  const { userInformationLoading, myOfferInformation } = useUserInformation();

  const [showOffer, setShowOffer] = useState(false);

  const showOfferHandler = function () {
    setShowOffer(true);
  };

  if (userInformationLoading) return <LoadingSpinner />;

  if (!profile.onboardingSurveyCompleted) return <UserOnboardingSurvey />;

  if (showOffer) return <YourOffer setShowOffer={setShowOffer} />;

  if (
    !userInformationLoading &&
    myOfferInformation &&
    myOfferInformation.active
  ) {
    return <ActiveOffer />;
  }

  return (
    <div className={classes['congrats-container']}>
      <Congrats>
        <h3>Congrats!</h3>
        <p className={classes['review-text']}>
          HiPo will review your info and update you on your application within
          24 hours.
        </p>

        {!userInformationLoading && myOfferInformation && (
          <Button onClick={showOfferHandler}>See Offers</Button>
        )}
      </Congrats>
    </div>
  );
}

export default MyDashboard;
