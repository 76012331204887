import React from 'react';
import classes from './LoadingSpinner.module.css';

function LoadingSpinner(props) {
  let style = {};
  if (props.width) {
    style = { width: props.width, height: props.width };
  }
  return (
    <div className={classes['lds-ring']}>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
      <div style={style}></div>
    </div>
  );
}

export default LoadingSpinner;
