import React, { useState } from 'react';
import classes from './AcceptOfferModal.module.css';
import Modal from '../../../../../../../../components/Modal/Modal';
import Button from '../../../../../../../../components/Button/Button';
import { useUserInformation } from '../../../../../../../../context/UserInformationContext';
import moment from 'moment';
import { useNotifications } from '../../../../../../../../context/NotificationContext';
import { acceptMyOfferRequest } from '../../../../../../../../services/userOffer';
import { useAuthentication } from '../../../../../../../../context/AuthenticationContext';

function AcceptOfferModal({ setShowAcceptOfferModal, setShowOffer }) {
  const { showNotification } = useNotifications();
  const { myOfferInformation, getMyInformationHandler } = useUserInformation();
  const { refreshProfile } = useAuthentication();

  const [acceptingOffer, setAcceptingOffer] = useState(false);

  const closeModalHandler = function () {
    setShowAcceptOfferModal(false);
  };

  const acceptOfferHandler = async function () {
    setAcceptingOffer(true);
    try {
      await acceptMyOfferRequest();

      await Promise.all([refreshProfile(), getMyInformationHandler()]);

      showNotification(
        'success',
        'Offer accepted successfully. Congratulations!',
        2000
      );

      setTimeout(() => {
        setAcceptingOffer(false);
        setShowOffer(false);
        closeModalHandler();
      }, 2000);
    } catch (err) {
      setAcceptingOffer(false);

      showNotification('error', err, 3000);
    }
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={acceptingOffer}
            type="danger"
            style={{ width: 200 }}
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button
            disabled={acceptingOffer}
            onClick={acceptOfferHandler}
            style={{ width: 200 }}
          >
            Accept Offer
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title={`Accept Offer`}
    >
      <div className={classes['offer-information-container']}>
        <div className={classes['hipo-text-container']}>
          <h3 className={classes['hipo-text']}>HiPo</h3>
        </div>

        <div className={classes['info-container']}>
          <div className={classes['info-section-container']}>
            <p>Offer Amount</p>
            <p>{myOfferInformation.offerAmount} USD</p>
          </div>

          <div className={classes['info-section-container']}>
            <p>Estimated Monthly Payment</p>
            <p>{myOfferInformation.estimatedMonthlyPayment}</p>
          </div>

          <div className={classes['info-section-container']}>
            <p>Percent of Monthly Income</p>
            <p>{myOfferInformation.monthlyPercentIncome}%</p>
          </div>

          <div className={classes['info-section-container']}>
            <p>Pay Off In</p>
            <p>{moment(myOfferInformation.payOffIn).format('MMMM Do YYYY')}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AcceptOfferModal;
