import React, { useEffect, useState } from 'react';
import classes from './Sidebar.module.css';
import Logo from '../Logo/Logo';
import HomeIcon from '../../assets/home.png';
import LogoutIcon from '../../assets/logout.png';
import { useAuthentication } from '../../context/AuthenticationContext';
import ProfileContainer from './components/ProfileContainer/ProfileContainer';
import { Link, useLocation } from 'react-router-dom';

const SIDEBAR_OPTIONS = {
  HOME: 'Home',
  HISTORY: 'History',
};

function Sidebar() {
  const location = useLocation();
  const { logout } = useAuthentication();

  const [selectedOption, setSelectedOption] = useState(SIDEBAR_OPTIONS.HOME);

  useEffect(
    function () {
      const pathname = location.pathname.substring(1);

      if (pathname === 'history') {
        setSelectedOption(SIDEBAR_OPTIONS.HISTORY);
      } else {
        setSelectedOption(SIDEBAR_OPTIONS.HOME);
      }
    },
    [location]
  );

  return (
    <div className={classes['sidebar-container']}>
      <div className={classes['logo-container']}>
        <Logo />
      </div>

      <nav className={classes['nav-container']}>
        <ul>
          <Link to="/">
            <li
              className={`${classes['li']} ${
                selectedOption === SIDEBAR_OPTIONS.HOME &&
                classes['selected-li']
              }`}
            >
              <img src={HomeIcon} alt="Home" className={classes['home-icon']} />
              <p className={classes['li-text']}>Home</p>
            </li>
          </Link>

          <Link to="/history">
            <li
              className={`${classes['li']} ${
                selectedOption === SIDEBAR_OPTIONS.HISTORY &&
                classes['selected-li']
              }`}
            >
              <img src={HomeIcon} alt="Home" className={classes['home-icon']} />
              <p className={classes['li-text']}>History</p>
            </li>
          </Link>

          <li onClick={logout} className={classes['li']}>
            <img
              src={LogoutIcon}
              alt="Logout"
              className={classes['logout-icon']}
            />
            <p className={classes['li-text']}>Logout</p>
          </li>
        </ul>

        <ProfileContainer />
      </nav>
    </div>
  );
}

export default Sidebar;
