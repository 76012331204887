import React from 'react';
import classes from './Input.module.css';

function Input(props) {
  const { label } = props;

  if (label) {
    return (
      <div className={classes['input-label-container']}>
        <p className={classes['label']}>{label}</p>
        <input {...props} className={classes['input']} />
      </div>
    );
  }

  return <input {...props} className={classes['input']} />;
}

export default Input;
