import React from 'react';
import classes from './Footer.module.css';
import Logo from '../Logo/Logo';

function Footer() {
  return (
    <footer className={classes['footer']}>
      <Logo />

      <p>
        Copyright &copy; {new Date().getFullYear()} Hipo. All rights reserved.
      </p>
    </footer>
  );
}

export default Footer;
