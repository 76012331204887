import React, { useState } from 'react';
import classes from './CreateUserOfferModal.module.css';
import Modal from '../../../../../../components/Modal/Modal';
import Button from '../../../../../../components/Button/Button';
import Input from '../../../../../../components/Input/Input';
import {
  getFormattedNumber,
  isInputValid,
} from '../../../../../../utils/helpers';
import { useNotifications } from '../../../../../../context/NotificationContext';
import { createUserOfferRequest } from '../../../../../../services/userOffer';

function CreateUserOfferModal({
  userOfferModalState,
  closeModal,
  invalidateUsersBasedOnOnboardingSurveyOfferStatuses,
}) {
  const { user } = userOfferModalState;

  const { showNotification } = useNotifications();

  const [offerAmount, setOfferAmount] = useState('');
  const [offerAmountTouched, setOfferAmountTouched] = useState(false);
  const [offerAmountIsValid, setOfferAmountIsValid] = useState(false);

  const [estimatedMonthlyPayment, setEstimatedMonthlyPayment] = useState('');
  const [
    estimatedMonthlyPaymentTouched,
    setEstimatedMonthlyPaymentTouched,
  ] = useState(false);
  const [
    estimatedMonthlyPaymentValid,
    setEstimatedMonthlyPaymentValid,
  ] = useState(false);

  const [monthlyPercentIncome, setMonthlyPercentIncome] = useState('');
  const [
    monthlyPercentIncomeTouched,
    setMonthlyPercentIncomeTouched,
  ] = useState(false);
  const [
    monthlyPercentIncomeIsValid,
    setMonthlyPercentIncomeIsValid,
  ] = useState(false);

  const [payOffIn, setPayOffIn] = useState('');
  const [payOffInTouched, setPayOffInTouched] = useState(false);
  const [payOffInIsValid, setPayOffInIsValid] = useState(false);

  const [creatingOffer, setCreatingOffer] = useState(false);

  const offerAmountChangeHandler = function (e) {
    const { value } = e.target;

    setOfferAmount(value);
    setOfferAmountTouched(true);
    setOfferAmountIsValid(value && !isNaN(value) && parseFloat(value) > 0);
  };

  const estimatedMonthlyPaymentChangeHandler = function (e) {
    const { value } = e.target;

    setEstimatedMonthlyPayment(value);
    setEstimatedMonthlyPaymentTouched(true);
    setEstimatedMonthlyPaymentValid(
      value && !isNaN(value) && parseFloat(value) > 0
    );
  };

  const monthlyPercentIncomeChangeHandler = function (e) {
    const { value } = e.target;

    setMonthlyPercentIncome(value);
    setMonthlyPercentIncomeTouched(true);
    setMonthlyPercentIncomeIsValid(
      value && !isNaN(value) && parseFloat(value) > 0
    );
  };

  const payOffInChangeHandler = function (e) {
    const { value } = e.target;

    setPayOffIn(value);
    setPayOffInTouched(true);
    setPayOffInIsValid(isInputValid(value, 4, false));
  };

  const createUserOfferHandler = async function () {
    setCreatingOffer(true);

    try {
      const requestBody = {
        offerAmount: parseFloat(offerAmount),
        monthlyPercentIncome: parseFloat(monthlyPercentIncome),
        estimatedMonthlyPayment: parseFloat(estimatedMonthlyPayment),
        payOffIn,
      };

      await createUserOfferRequest(user._id, requestBody);

      invalidateUsersBasedOnOnboardingSurveyOfferStatuses();

      showNotification('success', 'Approved Successfully!', 1000);

      setTimeout(() => {
        setCreatingOffer(false);
        closeModal();
      }, 1000);
    } catch (err) {
      setCreatingOffer(false);

      showNotification('error', err, 3000);
    }
  };

  const offerAmountInputStyle = {};
  if (offerAmountTouched && !offerAmountIsValid) {
    offerAmountInputStyle['border'] = '1px solid red';
  }
  const estimatedMonthlyPaymentInputStyle = {};
  if (estimatedMonthlyPaymentTouched && !estimatedMonthlyPaymentValid) {
    estimatedMonthlyPaymentInputStyle['border'] = '1px solid red';
  }
  const monthlyPercentIncomeInputStyle = {};
  if (monthlyPercentIncomeTouched && !monthlyPercentIncomeIsValid) {
    monthlyPercentIncomeInputStyle['border'] = '1px solid red';
  }
  const payOffInInputStyle = {};
  if (payOffInTouched && !payOffInIsValid) {
    payOffInInputStyle['border'] = '1px solid red';
  }

  const isApproveButtonDisabled =
    !offerAmountIsValid ||
    !estimatedMonthlyPaymentValid ||
    !monthlyPercentIncomeIsValid ||
    !payOffInIsValid;

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={creatingOffer}
            type="danger"
            style={{ width: 200 }}
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button
            disabled={isApproveButtonDisabled || creatingOffer}
            onClick={createUserOfferHandler}
            style={{ width: 200 }}
          >
            Approve
          </Button>
        </div>
      }
      closeModal={closeModal}
      title={`Create Offer --- User: ${user.fullName}`}
    >
      <div>
        <p className={classes['label']}>
          Offer Amount: {offerAmountIsValid && getFormattedNumber(offerAmount)}
        </p>
        <Input
          style={offerAmountInputStyle}
          placeholder="Offer Amount"
          onChange={offerAmountChangeHandler}
          value={offerAmount}
          type="number"
        />

        <p className={classes['label']}>
          Estimated Monthly Payment:{' '}
          {estimatedMonthlyPaymentValid &&
            getFormattedNumber(estimatedMonthlyPayment)}
        </p>
        <Input
          placeholder="Estimated Monthly Payment"
          style={estimatedMonthlyPaymentInputStyle}
          value={estimatedMonthlyPayment}
          onChange={estimatedMonthlyPaymentChangeHandler}
          type="number"
        />

        <p className={classes['label']}>
          Monthly Percent Income:{' '}
          {monthlyPercentIncomeIsValid &&
            getFormattedNumber(monthlyPercentIncome)}
        </p>

        <Input
          placeholder="Monthly Percent Income"
          style={monthlyPercentIncomeInputStyle}
          value={monthlyPercentIncome}
          onChange={monthlyPercentIncomeChangeHandler}
          type="number"
        />

        <p className={classes['label']}>
          Pay Off In: {payOffInIsValid && payOffIn}
        </p>

        <Input
          type="date"
          value={payOffIn}
          onChange={payOffInChangeHandler}
          style={payOffInInputStyle}
        />
      </div>
    </Modal>
  );
}

export default CreateUserOfferModal;
