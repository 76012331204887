import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/user-offers`;

export const CREATE_USER_OFFER_ENDPOINT = BASE_ENDPOINT;
export const GET_MY_OFFER_ENDPOINT = BASE_ENDPOINT;
export const CANCEL_MY_OFFER_ENDPOINT = BASE_ENDPOINT + '/cancel';
export const ACCEPT_MY_OFFER_ENDPOINT = BASE_ENDPOINT + '/accept';

export const createUserOfferRequest = async function (userId, requestBody) {
  try {
    const { data } = await axios.post(
      `${CREATE_USER_OFFER_ENDPOINT}/${userId}`,
      requestBody
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const getMyOfferRequest = async function () {
  try {
    const { data } = await axios.get(GET_MY_OFFER_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const cancelMyOfferRequest = async function (requestBody) {
  try {
    const { data } = await axios.patch(CANCEL_MY_OFFER_ENDPOINT, requestBody);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};

export const acceptMyOfferRequest = async function () {
  try {
    const { data } = await axios.patch(ACCEPT_MY_OFFER_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
