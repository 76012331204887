import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/rejected-user-offers`;

export const GET_MY_REJECTED_OFFERS_ENDPOINT = BASE_ENDPOINT;

export const getMyRejectedOffersRequest = async function () {
  try {
    const { data } = await axios.get(GET_MY_REJECTED_OFFERS_ENDPOINT);
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
