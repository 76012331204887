import React, { useState } from 'react';
import classes from './CancelOfferModal.module.css';
import Modal from '../../../../../../../../components/Modal/Modal';
import Button from '../../../../../../../../components/Button/Button';
import { useNotifications } from '../../../../../../../../context/NotificationContext';
import { cancelMyOfferRequest } from '../../../../../../../../services/userOffer';
import { useUserInformation } from '../../../../../../../../context/UserInformationContext';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../../../../../../../context/AuthenticationContext';

const CANCEL_REASONS = [
  'I am no longer seeking funding',
  'The terms are too complicated',
  'I prefer fixed payments each month over a % of my income',
  'Other: (allow the user to type this in)',
];

function CancelOfferModal({ setShowCancelOfferModal }) {
  const navigate = useNavigate();
  const { refreshProfile } = useAuthentication();
  const { showNotification } = useNotifications();
  const { getMyInformationHandler } = useUserInformation();

  const [selectedReasons, setSelectedReasons] = useState([]);
  const [cancellingOffer, setCancellingOffer] = useState(false);

  const [otherText, setOtherText] = useState('');

  const selectReasonHandler = function (reason) {
    setSelectedReasons((reasons) => {
      if (reasons.includes(reason))
        return reasons.filter((el) => el !== reason);

      return [...reasons, reason];
    });
  };

  const closeModalHandler = function () {
    setShowCancelOfferModal(false);
  };

  const cancelOfferHandler = async function () {
    setCancellingOffer(true);

    try {
      const requestBody = {
        canceledReasons: selectedReasons.filter(
          (reason) => reason !== CANCEL_REASONS[CANCEL_REASONS.length - 1]
        ),
        otherText,
      };
      await cancelMyOfferRequest(requestBody);

      await Promise.all([refreshProfile(), getMyInformationHandler()]);

      showNotification('success', 'Offer Canceled successfully!', 2000);

      setTimeout(() => {
        setCancellingOffer(false);
        closeModalHandler();
      }, 2000);

      navigate('/');
    } catch (err) {
      setCancellingOffer(false);
      showNotification('error', err, 3000);
    }
  };

  const otherTextChangeHandler = function (e) {
    setOtherText(e.target.value);
  };

  return (
    <Modal
      footer={
        <div className={classes['footer']}>
          <Button
            disabled={cancellingOffer}
            type="danger"
            style={{ width: 200 }}
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <Button
            disabled={cancellingOffer}
            onClick={cancelOfferHandler}
            style={{ width: 200 }}
          >
            Cancel Offer
          </Button>
        </div>
      }
      closeModal={closeModalHandler}
      title={`Cancel Offer`}
    >
      <div>
        {CANCEL_REASONS.map((reason) => (
          <div key={reason} className={classes['reason-container']}>
            <label>
              <input
                type="checkbox"
                checked={selectedReasons.includes(reason)}
                onChange={() => selectReasonHandler(reason)}
              />
              {reason}
            </label>
          </div>
        ))}

        {selectedReasons.includes(
          CANCEL_REASONS[CANCEL_REASONS.length - 1]
        ) && (
          <textarea
            onChange={otherTextChangeHandler}
            value={otherText}
            placeholder="Enter here"
            className={classes['textarea']}
          />
        )}
      </div>
    </Modal>
  );
}

export default CancelOfferModal;
