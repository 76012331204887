import React, { useState } from 'react';
import classes from './OnboardingSurveyOffersHistory.module.css';
import { useUserInformation } from '../../context/UserInformationContext';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import moment from 'moment';
import { getFormattedNumber } from '../../utils/helpers';
import SeeDetailsModal from './SeeDetailsModal/SeeDetailsModal';
import SeeCanceledOfferDetailsModal from './SeeCanceledOfferDetailsModal/SeeCanceledOfferDetailsModal';
import SeeRejectedOfferDetailsModal from './SeeRejectedOfferDetailsModal/SeeRejectedOfferDetailsModal';

function OnboardingSurveyOffersHistory() {
  const {
    userInformationLoading,
    myOnboardingSurveyOfferStatusInformation,
    myCanceledOffers,
    myOfferInformation,
    myRejectedOffers,
  } = useUserInformation();

  const [showSeeDetailsModal, setShowSeeDetailsModal] = useState(false);
  const [
    canceledOfferDetailsModalState,
    setCanceledOfferDetailsModalState,
  ] = useState({
    open: false,
    offer: undefined,
  });
  const [
    rejectedOfferDetailsModalState,
    setRejectedOfferDetailsModalState,
  ] = useState({
    open: false,
    offer: undefined,
  });

  if (userInformationLoading) return <LoadingSpinner />;

  const openSeeDetailsModalHandler = function () {
    setShowSeeDetailsModal(true);
  };

  const openSeeCanceledOfferDetailsModalHandler = function (offer) {
    setCanceledOfferDetailsModalState({
      open: true,
      offer,
    });
  };

  const openSeeRejectedOfferDetailsModalHandler = function (offer) {
    setRejectedOfferDetailsModalState({
      open: true,
      offer,
    });
  };

  const renderRejectedSurveyOffersHandler = function () {
    if (myRejectedOffers.length === 0) return null;

    return myRejectedOffers.map((offer) => {
      return (
        <div
          key={offer._id}
          className={classes['rejected-offer-status-info-container']}
        >
          <p>{moment(offer.updatedAt).format('DD/MM/YYYY')}</p>

          {offer.requestedAmount && (
            <p>${getFormattedNumber(offer.requestedAmount)}</p>
          )}

          <p>Rejected</p>

          <div
            onClick={() => openSeeRejectedOfferDetailsModalHandler(offer)}
            className={classes['btn-text']}
          >
            See Details
          </div>
        </div>
      );
    });
  };

  const renderCanceledSurveyOffersHandler = function () {
    if (myCanceledOffers.length === 0) return null;

    return myCanceledOffers.map((offer) => {
      return (
        <div
          key={offer._id}
          className={classes['canceled-offer-status-info-container']}
        >
          <p>{moment(offer.updatedAt).format('DD/MM/YYYY')}</p>

          {offer.offerAmount && <p>${getFormattedNumber(offer.offerAmount)}</p>}

          <p>Canceled</p>

          <div
            onClick={() => openSeeCanceledOfferDetailsModalHandler(offer)}
            className={classes['btn-text']}
          >
            See Details
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {showSeeDetailsModal && (
        <SeeDetailsModal setShowSeeDetailsModal={setShowSeeDetailsModal} />
      )}

      {canceledOfferDetailsModalState.open && (
        <SeeCanceledOfferDetailsModal
          offer={canceledOfferDetailsModalState.offer}
          closeModal={() =>
            setCanceledOfferDetailsModalState({ open: false, offer: undefined })
          }
        />
      )}

      {rejectedOfferDetailsModalState.open && (
        <SeeRejectedOfferDetailsModal
          offer={rejectedOfferDetailsModalState.offer}
          closeModal={() =>
            setRejectedOfferDetailsModalState({ open: false, offer: undefined })
          }
        />
      )}

      <div className={classes['surveys-container']}>
        <div>
          <h2>Current Survey:</h2>
          {myOnboardingSurveyOfferStatusInformation ? (
            <div className={classes['current-offer-status-info-container']}>
              <p>
                {moment(
                  myOnboardingSurveyOfferStatusInformation.updatedAt
                ).format('DD/MM/YYYY')}
              </p>

              {myOnboardingSurveyOfferStatusInformation.requestedAmount && (
                <p>
                  $
                  {getFormattedNumber(
                    myOnboardingSurveyOfferStatusInformation.requestedAmount
                  )}
                </p>
              )}

              {myOfferInformation && myOfferInformation.offerAmount && (
                <p>${myOfferInformation.offerAmount}</p>
              )}

              <p>
                {myOfferInformation?.active
                  ? 'Active'
                  : myOnboardingSurveyOfferStatusInformation.status}
              </p>

              {myOnboardingSurveyOfferStatusInformation.status !==
                'Pending' && (
                <div
                  onClick={openSeeDetailsModalHandler}
                  className={classes['btn-text']}
                >
                  See Details
                </div>
              )}
            </div>
          ) : (
            <p>No Results Found</p>
          )}
        </div>

        <div>
          <h2>Previous Surveys</h2>

          {myCanceledOffers.length + myRejectedOffers.length === 0 && (
            <p>No Results Found!</p>
          )}
          {renderCanceledSurveyOffersHandler()}
          {renderRejectedSurveyOffersHandler()}
        </div>
      </div>
    </>
  );
}

export default OnboardingSurveyOffersHistory;
