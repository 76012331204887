import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
// import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Signup from './pages/Signup/Signup';
import Notifications from './components/Notifications/Notifications';
import { useAuthentication } from './context/AuthenticationContext';
import MyDashboard from './pages/MyDashboard/MyDashboard';
import AppLayout from './components/AppLayout/AppLayout';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import AdminRoute from './AdminRoute';
import { AdminPanelProvider } from './context/AdminPanelContext';
import Landing from './pages/Landing/Landing';
import OnboardingSurveyOffersHistory from './pages/OnboardingSurveyOffersHistory/OnboardingSurveyOffersHistory';

function App() {
  const { isLoggedIn, checkingAutoLogin } = useAuthentication();

  if (checkingAutoLogin) return null;

  return (
    <>
      <Notifications />

      <BrowserRouter>
        <Routes>
          {isLoggedIn ? (
            <>
              <Route element={<AppLayout />}>
                <Route path="/" element={<MyDashboard />} />
                <Route
                  path="/history"
                  element={<OnboardingSurveyOffersHistory />}
                />

                <Route path="*" element={<Navigate to="/" />} />
              </Route>

              <Route
                path="/admin-panel"
                element={
                  <AdminRoute>
                    <AdminPanelProvider>
                      <AdminPanel />
                    </AdminPanelProvider>
                  </AdminRoute>
                }
              />
            </>
          ) : (
            <>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/" element={<Landing />} />

              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />

              <Route path="*" element={<Navigate to="/" />} />
            </>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
