import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthenticationProvider } from './context/AuthenticationContext';
import { NotificationProvider } from './context/NotificationContext';
import { UserInformationProvider } from './context/UserInformationContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <QueryClientProvider client={queryClient}>
    <NotificationProvider>
      <AuthenticationProvider>
        <UserInformationProvider>
          <App />
        </UserInformationProvider>
      </AuthenticationProvider>
    </NotificationProvider>
  </QueryClientProvider>
);
