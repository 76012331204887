import React, { useState } from 'react';
import classes from './Select.module.css';
import DownIcon from '../../assets/down-icon.png';

function Select(props) {
  const { options, placeholder, selectedOption, setSelectedOption } = props;
  const [showOptions, setShowOptions] = useState(false);

  const selectOptionHandler = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  return (
    <div
      style={props.style ? { ...props.style } : {}}
      className={classes['select-container']}
    >
      <div
        onClick={() => setShowOptions((prevState) => !prevState)}
        className={classes['selected-option']}
      >
        {selectedOption ? (
          <p className={classes['selected-option']}>{selectedOption.value}</p>
        ) : (
          <p className={classes['selected-option-placeholder']}>
            {placeholder}
          </p>
        )}

        <img src={DownIcon} className={classes['white-down-icon']} alt="Open" />
      </div>

      {showOptions && (
        <div className={classes['options-container']}>
          {options.map((option) => {
            return (
              <div
                key={option.value}
                onClick={() => selectOptionHandler(option)}
                className={classes['option-container']}
              >
                <p>{option.value}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default Select;
