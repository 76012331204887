import React from 'react';
import Button from '../../../../components/Button/Button';
import { useNotifications } from '../../../../context/NotificationContext';
import { getAllEmailSubscriptionsRequest } from '../../../../services/emailSubscription';
import { generateCSVLink } from '../../../../utils/helpers';

function EmailSubscriptions() {
  const { showNotification } = useNotifications();

  const exportEmailSubscriptionsHandler = async function () {
    try {
      const { data } = await getAllEmailSubscriptionsRequest();

      const emails = data.map(({ email }) => email);
      const csvContent = generateCSVContent(emails);

      generateCSVLink(csvContent, 'email_subscriptions.csv');
    } catch (err) {
      showNotification('error', err, 3000);
    }
  };

  const generateCSVContent = (emails) => {
    let csvContent = 'Email\n';

    emails.forEach((email) => {
      const escapedEmail = email.replace(/"/g, '""');
      csvContent += `"${escapedEmail}"\n`;
    });

    return csvContent;
  };

  return (
    <div>
      <Button type="primary" onClick={exportEmailSubscriptionsHandler}>
        Export Email Subscriptions
      </Button>
    </div>
  );
}

export default EmailSubscriptions;
