import axios from 'axios';
import { handleError } from './handleError';

const BASE_ENDPOINT = `/api/canceled-user-onboarding-surveys`;

export const GET_CANCELED_ONBOARDING_SURVEY_INFORMATION_FOR_ALL_USERS_ENDPOINT = BASE_ENDPOINT;

export const getCanceledOnboardingSurveyInformationForAllUsersRequest = async function () {
  try {
    const { data } = await axios.get(
      GET_CANCELED_ONBOARDING_SURVEY_INFORMATION_FOR_ALL_USERS_ENDPOINT
    );
    return data;
  } catch (err) {
    throw handleError(err);
  }
};
