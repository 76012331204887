import React from 'react';
import Button from '../../../../components/Button/Button';
import { getAllEstimatedLifetimeEarningsRequest } from '../../../../services/estimatedLifetimeEarningsQuestionnaire';
import { useNotifications } from '../../../../context/NotificationContext';
import { generateCSVLink } from '../../../../utils/helpers';

function LifeTimeEarnings() {
  const { showNotification } = useNotifications();

  const generateLifetimeEarningsCSVHandler = async function () {
    try {
      const { data } = await getAllEstimatedLifetimeEarningsRequest();

      const csvContent = generateCSVContent(
        data.map(({ email, generatedLifetimeEarnings }) => ({
          email,
          estimatedLifetimeEarnings: generatedLifetimeEarnings,
        }))
      );

      generateCSVLink(csvContent, 'estimated_lifetime_earnings.csv');
    } catch (err) {
      showNotification('error', err, 3000);
    }
  };

  function generateCSVContent(data) {
    const csvContent =
      'Email,Estimated Lifetime Earnings\n' +
      data.map((row) => Object.values(row).join(',')).join('\n');

    return csvContent;
  }

  return (
    <div>
      <Button onClick={generateLifetimeEarningsCSVHandler} type="primary">
        Generate Lifetime Earnings
      </Button>
    </div>
  );
}

export default LifeTimeEarnings;
